angular.module('qualita.components', []);
/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.authorization', ['ngResource', 'checklist-model'])
    .config(
        ['$stateProvider', '$urlMatcherFactoryProvider', '$urlRouterProvider',
            function($stateProvider, $urlMatcherFactoryProvider, $urlRouterProvider) {
                $urlMatcherFactoryProvider.strictMode(false);
                $urlRouterProvider.otherwise('/main');

                $stateProvider
                    .state('app.usuarios', {
                        url: '/usuarios',
                        templateUrl: '../../views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Usuarios'
                        }
                    })
                    .state('app.usuarios.list', {
                        url: '',
                        templateUrl: '../../views/template_base/list.html',
                        controller: 'UsuariosIndexCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Usuarios',
                            description: 'Listado de Usuarios',
                            skip: true
                        }
                    })
                    .state('app.usuarios.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/usuarios/form.html',
                        controller: 'UsuariosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Nuevo Usuario',
                            description: 'Creación de un nuevo usuario'
                        },
                        resolve: {
                            usuarioPrepService: function() { return; }
                        }
                    })
                    .state('app.usuarios.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/usuarios/form.html',
                        controller: 'UsuariosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Editar Usuario',
                            description: 'Edición del usuario #'
                        },
                        resolve: {
                            usuarioPrepService: usuarioPrepService
                           }
                    })
                    .state('app.usuarios.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/usuarios/form.html',
                        controller: 'UsuariosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Ver Usuario',
                            description: 'Ver usuario #'
                        },
                        resolve: {
                            usuarioPrepService: usuarioPrepService
                        }
                    })
                    .state('app.usuarios.changepassword', {
                        url: '/:id/changepass/:admin',
                        templateUrl: 'qualita-components/authorization/views/usuarios/changepassword.html',
                        controller: 'UsuariosChangePasswordCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Cambiar contraseña del usuario',
                            description: 'Cambio de contraseña del usuario #'
                        }
                    }).state('app.certificado', {
                        url: '/certificado',
                        templateUrl: '../../views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Certificado'
                        }
                    }).state('app.certificado.edit', {
                        url: '',
                        templateUrl: 'qualita-components/authorization/views/certificado/form.html',
                        controller: 'CertificadoFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.certificado',
                            label: 'Actualización de certificado y logo',
                            description: 'Actualización de certificado y logo'
                        }
                    }).state('app.sucursales', {
                        url: '/sucursales',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Sucursales'
                        }
                    })
                    .state('app.sucursales.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'SucursalesIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Sucursales',
                            description: 'Listado de Sucursales',
                            skip: true
                        }
                    })
                    .state('app.sucursales.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/sucursales/form.html',
                        controller: 'SucursalesFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Nueva Sucursal',
                            description: 'Creación de una nueva sucursal'
                        },
                        resolve: {
                            sucursalPrepService: function() { return; }
                        }
                    })
                    .state('app.sucursales.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/sucursales/form.html',
                        controller: 'SucursalesFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Editar Sucursal',
                            description: 'Edición de la Sucursal #'
                        },
                        resolve: {
                            sucursalPrepService: sucursalPrepService
                        }
                    })
                    .state('app.sucursales.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/sucursales/form.html',
                        controller: 'SucursalesFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Ver Sucursal',
                            description: 'Ver Sucursal #'
                        },
                        resolve: {
                            sucursalPrepService: sucursalPrepService
                        }
                    }) .state('app.empresas', {
                        url: '/empresas',
                        templateUrl: '../../views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Empresas'
                        }
                    }).state('app.empresas.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'EmpresaIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.empresas',
                            label: 'empresas',
                            description: 'Listado de empresas',
                            skip: true
                        }
                    })
                    .state('app.empresas.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/empresas/form.html',
                        controller: 'EmpresasFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.empresas',
                            label: 'Nueva Empresa',
                            description: 'Creación de una nueva sucursal'
                        },
                        resolve: {
                            empresaPrepService: function() { return; }
                        }
                    })
                    .state('app.empresas.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/empresas/form.html',
                        controller: 'EmpresasFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.empresas',
                            label: 'Editar Empresa',
                            description: 'Edición de la Sucursal #'
                        },
                        resolve: {
                            empresaPrepService: empresaPrepService
                        }
                    })
                    .state('app.empresas.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/empresas/form.html',
                        controller: 'EmpresasFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.empresas',
                            label: 'Ver Empresa',
                            description: 'Ver Sucursal #'
                        },
                        resolve: {
                            empresaPrepService: empresaPrepService
                        }
                    })

            }]);

function usuarioPrepService(UsuarioFactory, $stateParams) {
    return UsuarioFactory.get($stateParams.id, "UsuarioForm").$promise;
}
usuarioPrepService.$inject = ["UsuarioFactory", "$stateParams"];

function sucursalPrepService(SucursalesFactory, $stateParams) {
    return SucursalesFactory.get($stateParams.id, 'SucursalForm').$promise;
}
sucursalPrepService.$inject = ["SucursalesFactory", "$stateParams"];

function empresaPrepService(EmpresaFactory, $stateParams) {
    return EmpresaFactory.get($stateParams.id, 'BaseDatatable').$promise;
}
empresaPrepService.$inject = ["EmpresaFactory", "$stateParams"];

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module("qualita.authorization").factory("AuthorizationLangFactory", [
  "$translatePartialLoader",
  "$translate",
  function(
    $translatePartialLoader,
    $translate
  ) {
    var translationsNeeded = [
      "ADMINISTRACION",
      "ID",
      "NOMBRE",
      "ESTADO",
      "OPERACIONES_PERIODO",
      "FECHA_DESDE",
      "FECHA_HASTA",
      "NOMBRES",
      "APELLIDOS",
      "CUENTA",
      "DESCRIPCION",
      "PAIS",
      "CIUDAD",
      "DIRECCION",
      "TELEFONO",
      "NOMBRE_USUARIO",
      "NUEVO_ACCESO_SISTEMA",
      "FOTO",
      "EDITAR_ACCESO_SISTEMA",
      "VER_ACCESO_SISTEMA",
      "MSG_ERROR_PERSISTIR",
      "GUARDADO_LOCALMENTE",
      "ASEGURECE_TENGA_PERMISO",
      "FORMULARIO_INVALIDO",
      "NUEVO_ROL",
      "EDITAR_ROL",
      "VER_ROL",
      "EDIT_SUCURSAL",
      "NUEVA_SUCURSAL",
      "EDITAR_SUCURSAL",
      "VER_SUCURSAL",
      "NEW_UNIDAD_NEGOCIO",
      "EDIT_UNIDAD_NEGOCIO",
      "VER_UNIDAD_NEGOCIO",
      "PASSWORD_INCORRECTO",
      "NEW_USUARIO",
      "EDIT_USUARIO",
      "VER_USUARIO",
      "PASWORD_NO_COINCIDE",
      "USUARIO_MISMO_NOMBRE",
      "USUARIO",
      "SUCURSALES_UNIDADES",
      "POR_DEFECTO",
      "AGREGAR_SUCURSAL",
      "ATRAS",
      "UNIDAD_NEGOCIO",
      "SUCURSALES",
      "SUURSAL_DEFECTO",
      "OPERATION",
      "DATE_FROM",
      "DATE_TO",
      "PERMISOS",
      "UNIDADES_NEGOCIO",
      "OPERATION",
      "DATE_FROM",
      "DATE_TO",
      "PERMISOS",
      "ACTIVE",
      "ROLS",
      "MODULO",
      "FILTRAR_POR",
      "FORMULARIO",
      "PERMISO",
      "APLICAR_TODOS",
      "LIMPIAR_TODOS",
      "DESPLIEGUE_CATEGORIA",
      "UNIDADES_NEGOCIO",
      "CURRENT_PASSWORD",
      "NEW_PASSWORD",
      "REPEAT_PASSWORD",
      "PASSWORD",
      "PERSONALIZAR_PERMISOS",
      "USUARIO_CONTARA",
      "NUEVO_PERIODO_ADMINISTRATIVO",
      "EDITAR_PERIODO_ADMINISTRATIVO",
      "VER_PERIODO_ADMINISTRATIVO",
      "CORREO",
      "PERIODO_ADMINISTRATIVO_MODAL_ABRIR_TITULO",
      "PERIODO_ADMINISTRATIVO_MODAL_CERRAR_TITULO",
      "PERIODO_ADMINISTRATIVO_MODAL_ABRIR_MENSAJE",
      "PERIODO_ADMINISTRATIVO_MODAL_CERRAR_MENSAJE",
      "BRANCH_OFFICE",
      "STAMPING_NUMBER",
      "STAMPING_CLASS",
      "NUMBER_START",
      "NUMBER_END",
      "STAMPING_TITLE",
      "STAMPING_DELETE_ERROR",
      "NEW_STAMPING",
      "EDIT_STAMPING",
      "VIEW_STAMPING",
      "CHANGE_PASSWORD",
      "PERSONAL_INFO",
      "UNIDAD_NEGOCIO_NAME_WARNING",
      "SUCURSAL_NAME_WARNING",
      "CLONAR",
      "INVALID_DATES",
      "INVALID_NUMERO_INICIAL_FINAL",
      "DELETE_USUARIO",
      "DELETE_CONFIRMATION",
      "DELETE_WARNING",
      "DELETE",
      "VIEW",
      "EDIT",
      "OK",
      "CANCEL",
      "CODE",
      "PUNTO_EMISION",
      "PUNTOS_EMISION",
      "TIPO_COMPROBANTE_ASOCIADO",
      "ALL_PUNTOS_EMISION_NUMERO_INICIAL_MENOR_NUMERO_FINAL",
      "PERIODO_ADMINISTRATIVO_DELETE_CHECK",
      "NUMERO_ACTUAL",
      "ENTIDAD",
      "EDIT_NUMERO_ACTUAL",
      "NUMERO_RECIBO_ADMINISTRACION",
      "ERROR_NUMERO_TIMBRADO_REPETIDO",
      "ERROR_NUMERO_TIMBRADO_CLASE_REPETIDO",
      "RESPUESTA_LOTE",
      "CODIGO",
      "IMPORT_CLIENTES",
      "TIPO_CONTRIBUYENTE",
      "PAIS",
      "DIRECCION",
      "NUMERO_CASA",
      "RUC_USUARIO"
    ];

    return {
      getTranslations: function() {
        $translatePartialLoader.addPart("administracion");
        return $translate.refresh().then(function() {
          return $translate(translationsNeeded);
        });
      }
    };
  }
]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
.factory('UsuarioFactory', UsuarioFactory);

UsuarioFactory.$inject = ['$resource', 'baseurl', '$log'];

function UsuarioFactory($resource, baseurl, $log) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    allUsersWithoutAccess: allUsersWithoutAccess,
    changePassword: changePassword,
    changeMyPassword: changeMyPassword
  };

  var Usuario = $resource( baseurl.getBaseUrl()  + "/usuarios/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    },
    usersWithoutAccess: {
      method: 'GET',
      url: baseurl.getBaseUrl() + "/usuarios/userswithoutaccess",
      isArray: true
    },
    changePassword: {
      method: 'PUT',
      url: baseurl.getBaseUrl() + '/usuarios/:id/changepassword'
    },

    changeMyPassword: {
        method: 'PUT',
        url: baseurl.getBaseUrl() + '/usuarios/:id/changemypassword'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'UsuarioList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'UsuarioList';
    }
    return Usuario.query(params);
  }

  function allUsersWithoutAccess() {
    return Usuario.usersWithoutAccess();
  }

  function get(id, view) {
    return Usuario.get({id: id, view: view!=undefined ? view : 'UsuarioForm'});
  }

  function create(attrs) {
    return new Usuario(attrs);
  }

  function save(Usuario) {
    return (Usuario.id) ? Usuario.$update() : Usuario.$save();
  }

  function remove(Usuario) {
    return Usuario.$remove();
  }

  function changePassword (usuario) {
    return usuario.$changePassword();
  }

  function changeMyPassword (usuario) {
    return usuario.$changeMyPassword();
  }
}


/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.authorization')
  .factory('RolesFactory', ['$resource', 'baseurl', function($resource, baseurl) {
    var Rol = $resource( baseurl.getBaseUrl() + '/roles/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return Rol.query(params);
      },

      get: function(id, view) {
        //return Rol.get({id: id});
        return Rol.get({id: id, view: view!=undefined ? view : 'base'});
      },

      create: function(attrs) {
        return new Rol(attrs);
      },

      save: function(rol) {
        return (rol.id) ? rol.$update() : rol.$save();
      },

      remove: function(rol) {
        return rol.$remove();
      }
    };
  }
  ]);

'use strict';


angular.module('qualita.authorization')
  .controller('UsuariosIndexCtrl', ["UsuarioFactory", "$scope", "AuthorizationLangFactory", "$filter", "filterFactory", function (UsuarioFactory, $scope, AuthorizationLangFactory, $filter, filterFactory) {

    var defaultColumnOrder = ['id','usuario.username', 'nombre','apellido', 'email', 'empresa.nombreEmpresa'];
    AuthorizationLangFactory.getTranslations().then(function() {
        $scope.options = {
          'resource': 'usuarios',
          'title': 'Usuarios',
          'factory': UsuarioFactory,
          'view': 'UsuarioList',
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
            {'data': 'usuario.username', 'title': $filter('translate')('USER_NAME')},
            {'data': 'nombre', 'title': $filter('translate')('NOMBRES')},
            {'data': 'apellido', 'title': $filter('translate')('APELLIDOS')},
            {'data': 'email', 'title': $filter('translate')('CORREO')},
            {'data': 'empresa.nombreEmpresa', 'title': $filter('translate')('EMPRESA')}
          ],
          'hasOptions' : true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'hideRemoveMenu': true
          };
        });
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('UsuariosChangePasswordCtrl', UsuariosChangePasswordCtrl);

UsuariosChangePasswordCtrl.$inject = ['$rootScope', '$scope', 'UsuarioFactory', '$q', '$log', '$location', '$stateParams', 'notify', 'AuthorizationLangFactory',
'$filter'];

function UsuariosChangePasswordCtrl($rootScope, $scope, UsuarioFactory, $q, $log, $location, $stateParams, notify, AuthorizationLangFactory, $filter) {
  var vm = this;

  vm.usuario = {id: localStorage.getItem('USUARIO_ID') };

  vm.cancel = cancel;

  vm.submit = submit;

  vm.submited = false;

  AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      activate();
    });

  function activate() {
    $rootScope.isProcessing = false;
    vm.title = $filter('translate')('CHANGE_PASSWORD');
    if($stateParams.admin === "true") {
      vm.dontAskForPrevious = true;
    }
  }

  function submit() {
    vm.submited = true;
    if($scope.usuarioForm.$valid && (vm.newPasswordRepeated === vm.usuario.newPassword)) {
      $rootScope.isProcessing = true;
      submitChange().then(function (response) {
        $location.path('/');
      }, function(response) {
        $rootScope.isProcessing = false;
        if(response.data.errorcode === "wrong_pass") {
          notify({ message: $filter('translate')('PASSWORD_INCORRECTO'), classes: 'alert-danger', position: 'right'});
        }
      });
    }

    function submitChange() {
      var usuario = UsuarioFactory.create(vm.usuario);
      if($stateParams.admin === "true") {
        return UsuarioFactory.changePassword(usuario);
      }
      else {
        return UsuarioFactory.changeMyPassword(usuario);
      }
    }
  }

  function cancel() {
    $location.path("/");
  }

  $scope.resource = 'usuarios'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('UsuariosFormCtrl', ["$rootScope", "$scope", "UsuarioFactory", "RolesFactory", "$location", "filterFactory", "notify", "usuarioPrepService", "$state", "$localForage", "AuthorizationLangFactory", "$filter", "EmpresaFactory", function ($rootScope, $scope, UsuarioFactory, RolesFactory, $location,
                                            filterFactory, notify, usuarioPrepService, $state, $localForage, AuthorizationLangFactory, $filter,
                                            EmpresaFactory) {

    activate();

    /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
    $scope.staticFilterEmpresa = [];
    $scope.empresaFactory = EmpresaFactory;

    function activate() {
      AuthorizationLangFactory.getTranslations().then(function(translations) {
        $scope.translations=translations;
        $rootScope.isProcessing = false;
        if($state.is('app.usuarios.new')) {
          activateNew();
        } else if($state.is('app.usuarios.edit')) {
          activateEdit();
        } else if($state.is('app.usuarios.view')) {
          activateView();
        }
      });
    }

    function activateNew() {
      $scope.title = $filter('translate')('NEW_USUARIO');
      $scope.newUser = true;
      $scope.rePassword = "";
    }

    function activateEdit() {
      $scope.title = $filter('translate')('EDIT_USUARIO');
      $scope.usuario = usuarioPrepService;
      $scope.entidadId=$scope.usuario.id;
      $scope.entidad="Usuario";
      $scope.usuario.password = undefined;
      $scope.isEditing = true;
    }

    function activateView() {
      $scope.title =$filter('translate')('VER_USUARIO');
      $scope.usuario = usuarioPrepService;
      $scope.entidadId=$scope.usuario.id;
      $scope.entidad="Usuario";
      $scope.usuario.password = undefined;
      $scope.view = true;
    }

    $scope.resource = 'usuarios';

    $rootScope.isProcessing = false;
    var filterRoles = {};

    filterRoles.search = filterFactory.single({ path: 'personalizado',
    equals: false}).value();

    $scope.roles = RolesFactory.all(filterRoles);

    $scope.permAsignados = {permisos: []};
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'descripcion',
      sortAsc: true
    }]).value();

    $scope.rolesAsignados = {};

    $scope.cancel = function() {
      $location.path("/usuarios");
    };

    $scope.submit = function() {
      if ($scope.usuario.usuario.roles.length > 1){
        notify({ message: "El usuario sólo puede tener asignado un rol", classes: 'alert-danger', position: 'right'});
        return;
      }
      submit();
    }

    function submit() {
      $scope.submited = true;
      if ($scope.usuarioForm.$valid) {
        if ($scope.rePassword !== $scope.usuario.usuario.password) {
          notify({ message: $filter('translate')('PASWORD_NO_COINCIDE'), classes: 'alert-danger', position: 'right'});
        } else {
          $rootScope.isProcessing = true;
          var roles = [{id: $scope.usuario.usuario.roles[0].id}]
          $scope.usuario.usuario.roles = roles;
          $scope.usuario.usuario.activo = true;
          $scope.usuario.usuario.personalizado =  false;

          var usuario = UsuarioFactory.create($scope.usuario);
          usuario.empresa = {id: $scope.usuario.empresa.id};

          UsuarioFactory.save(usuario).then(function(resp) {
            $state.go('app.usuarios.list', {idUsuario:resp.id});
          }, function (data) {
            $rootScope.isProcessing = false;
            if(data.data && data.data[0].constraint === "username"){
              errorHandler(data.data);
            } else {
              var msg = $filter('translate')('MSG_ERROR_PERSISTIR');
              if (!$scope.usuario.id) msg += $filter('translate')('GUARDADO_LOCALMENTE');
              notify({message: msg, classes: 'alert-danger', position: 'right'});
              $localForage.getItem('usuarios').then(function (value) {
                value = value || [];
                value.unshift($scope.usuario);
                if (!$scope.usuario.id) $localForage.setItem('usuarios', value);
              });
            }
          });
        }
      }
    }

    function errorHandler(e) {
      var msg = '';
      _.forEach(e, function(error) {
        if(error.message=="Not unique field: username"){
          msg += '\n\n' + $filter('translate')('USUARIO_MISMO_NOMBRE')+"\n"
        } else {
          msg += '\n\n' + error.message + '.'
        }
      });
      notify({ message: msg, classes: 'alert-danger', position: 'right' });
    }
  }]);

'use strict';


angular.module('qualita.authorization')
  .controller('SucursalesIndexCtrl', SucursalesIndexCtrl);

SucursalesIndexCtrl.$inject = ["SucursalesFactory", "$log", "$scope", "notify",
"filterFactory", "ReportTicketFactory", "$window", "AuthorizationLangFactory", "CsvFactory", "UtilFactory", "baseurl",
"$filter"];

function SucursalesIndexCtrl(SucursalesFactory, $log, $scope, notify,
filterFactory, ReportTicketFactory, $window, AuthorizationLangFactory,
CsvFactory, UtilFactory, baseurl, $filter) {
  var vm = this;

  var defaultColumnOrder = ['id','nombre', 'ciudad', 'departamento', 'distrito', 'codigoEstablecimiento', 'empresa.nombreEmpresa'];

  AuthorizationLangFactory.getTranslations().then(function(translations) {
  vm.options = {
    'resource': 'sucursales',
    'title': 'Sucursales',
    'factory': SucursalesFactory,
    'view': 'SucursalList',
    'defaultColumnOrder': defaultColumnOrder,
    'columns': [
      {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
      {'data': 'nombre', 'title': $filter('translate')('NOMBRE')},
      {'data': 'ciudad', title: $filter('translate')('CIUDAD')},
      {'data': 'departamento', title: $filter('translate')('DEPARTAMENTO')},
      {'data': 'distrito', title: $filter('translate')('DISTRITO')},
      {'data': 'codigoEstablecimiento', title: $filter('translate')('CODIGO_ESTABLECIMIENTO'), 'class':'dt-right'},
      {'data': 'empresa.nombreEmpresa', 'title': $filter('translate')('EMPRESA')}
    ],
    'hasOptions' : true,
    'defaultOrderColumn' : 0,
    'defaultOrderDir' : "desc",
    'extraMenuOptions':
      [
        {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("sucursales", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
      ]
  };

 /* var empresasFilter = {};
  empresasFilter.search = filterFactory.and([{
      path: "empresa.id",
      equals: localStorage.getItem('EMPRESA')
  }]).value();

  vm.options.staticFilter = empresasFilter;*/
});
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('SucursalesFormCtrl', SucursalesFormCtrl);

SucursalesFormCtrl.$inject = ['$rootScope', '$scope', 'SucursalesFactory', '$log', '$location', '$state', 'sucursalPrepService',
'$q', '$stateParams','AuthorizationLangFactory', 'notify', '$filter', 'EmpresaFactory'];

function SucursalesFormCtrl($rootScope, $scope, SucursalesFactory, $log, $location, $state, sucursalPrepService,
  $q, $stateParams,AuthorizationLangFactory, notify, $filter, EmpresaFactory) {
  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;

  /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
  $scope.staticFilterEmpresa = [];
  $scope.empresaFactory = EmpresaFactory;

  activate();

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      if($state.is("app.sucursales.new")) {
        activateNew();
      } else if($state.is("app.sucursales.edit")) {
        activateEdit();
      } else if($state.is("app.sucursales.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVA_SUCURSAL');
    vm.sucursal = {};
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDITAR_SUCURSAL');
    vm.sucursal = sucursalPrepService;
    $scope.entidadId=vm.sucursal.id;
    $scope.entidad="Sucursal";
  }

  function activateView() {
    vm.title = $filter('translate')('VER_SUCURSAL');
    vm.sucursal = sucursalPrepService;
    $scope.entidadId=vm.sucursal.id;
    $scope.entidad="Sucursal";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;
    if($scope.SucursalesForm.$valid) {
      $rootScope.isProcessing = true;
      submitSucursal().then(function (sucursal) {
        $log.info("sucursal almacenada");
        $location.path('/sucursales');
      }, function(error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    }

    function submitSucursal() {
      var sucursal = SucursalesFactory.create(vm.sucursal);
      return SucursalesFactory.save(sucursal);
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $log.info("cancelar sucursal");
    $location.path("/sucursales");
  }

  $scope.resource = 'sucursales'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('SucursalesEditCtrl', SucursalesEditCtrl);

SucursalesEditCtrl.$inject = ['$rootScope', '$scope', 'SucursalesFactory', '$stateParams', '$q', 'filterFactory', 'UnidadesNegocioFactory', 
'$log', '$location','AuthorizationLangFactory', '$filter'];

function SucursalesEditCtrl($rootScope, $scope, SucursalesFactory, $stateParams, $q, filterFactory, UnidadesNegocioFactory, 
  $log, $location, AuthorizationLangFactory, $filter) {
  var vm = this;

  //vm.chosenUnidadesNegocio = [];

  vm.sucursal = {};

  AuthorizationLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      vm.title = $filter('translate')('EDIT_SUCURSAL');
    });

  vm.unidadesNegocio = [];

  vm.cancel = cancel;

  vm.submit = submit;

  activate();

  function activate() {
    $rootScope.isProcessing = false;

  }

  function cancel() {
    $location.path("/sucursales");
  }

  function getUnidadesNegocio() {
    return UnidadesNegocioFactory.all().$promise.then(function(response) {
      vm.unidadesNegocio = response;
      return vm.unidadesNegocio;
    });
  }

  function submit() {
    vm.submited = true;
    if($scope.SucursalesForm.$valid) {
      $rootScope.isProcessing = true;
      submitSucursal().then(function (sucursal) {
        //$log.info("sucursal actualizada");
        $location.path('/sucursales');
      });
    }

    function submitSucursal() {

      var sucursal = SucursalesFactory.create(vm.sucursal);
      return SucursalesFactory.save(sucursal);
    }
  }

  $scope.resource = 'sucursales';
}

'use strict';


angular.module('qualita.authorization')
  .controller('EmpresaIndexCtrl', EmpresaIndexCtrl);

EmpresaIndexCtrl.$inject = ["EmpresaFactory", "$log", "$scope", "notify",
"filterFactory", "ReportTicketFactory", "$window", "AuthorizationLangFactory", "CsvFactory", "UtilFactory", "baseurl",
"$filter"];

function EmpresaIndexCtrl(EmpresaFactory, $log, $scope, notify,
filterFactory, ReportTicketFactory, $window, AuthorizationLangFactory,
CsvFactory, UtilFactory, baseurl, $filter) {
  var vm = this;

  var defaultColumnOrder = ['id','nombreEmpresa', 'nombreFantasia', 'numeroDocumento', 'contactoComercial', 'emailContacto'];
  AuthorizationLangFactory.getTranslations().then(function(translations) {
  vm.options = {
    'resource': 'empresas',
    'title': 'Empresas',
    'factory': EmpresaFactory,
    'view': 'BaseDatatable',
    'defaultColumnOrder': defaultColumnOrder,
    'columns': [
      {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
      {'data': 'nombreEmpresa', 'title': $filter('translate')('NOMBRE_EMPRESA')},
      {'data': 'nombreFantasia', 'title': $filter('translate')('NOMBRE_FANTASIA')},
      {'data': 'numeroDocumento', 'title': $filter('translate')('NRO_DOCUMENTO')},
      {'data': 'contactoComercial', 'title': $filter('translate')('CONTACTO_COMERCIAL')},
      {'data': 'emailContacto', 'title': $filter('translate')('EMAIL_CONTACTO')}
    ],
    'hasOptions' : true,
    'defaultOrderColumn' : 0,
    'defaultOrderDir' : "desc",
    'extraMenuOptions':
      [
        {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("Empresa", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
      ]
  };
});
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('EmpresasFormCtrl', EmpresasFormCtrl);

EmpresasFormCtrl.$inject = ['$rootScope', '$scope', 'EmpresaFactory', '$location', '$state',
'AuthorizationLangFactory', 'notify', '$filter', 'empresaPrepService'];

function EmpresasFormCtrl($rootScope, $scope, EmpresaFactory, $location, $state,
  AuthorizationLangFactory, notify, $filter, empresaPrepService) {
  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;

  activate();

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      if($state.is("app.empresas.new")) {
        activateNew();
      } else if($state.is("app.empresas.edit")) {
        activateEdit();
      } else if($state.is("app.empresas.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVA_EMPRESA');
    vm.empresa = {};
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDITAR_EMPRESA');
    vm.empresa = empresaPrepService;
    $scope.entidadId=vm.empresa.id;
    $scope.entidad="Empresa";
  }

  function activateView() {
    vm.title = $filter('translate')('VER_EMPRESA');
    vm.empresa = empresaPrepService;
    $scope.entidadId=vm.empresa.id;
    $scope.entidad="Empresa";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;
    if($scope.EmpresasForm.$valid) {
      $rootScope.isProcessing = true;
      submitEmpresa().then(function () {
        $location.path('/empresas');
      }, function(error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    }

    function submitEmpresa() {
      var empresa = EmpresaFactory.create(vm.empresa);
      return EmpresaFactory.save(empresa);
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $location.path("/empresas");
  }

  $scope.resource = 'Empresas'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('EmpresasEditCtrl', EmpresasEditCtrl);

EmpresasEditCtrl.$inject = ['$rootScope', '$scope', 'EmpresasFactory', '$stateParams', '$q', 'filterFactory', 'UnidadesNegocioFactory',
'$log', '$location','AuthorizationLangFactory', '$filter'];

function EmpresasEditCtrl($rootScope, $scope, EmpresasFactory, $stateParams, $q, filterFactory, UnidadesNegocioFactory,
  $log, $location, AuthorizationLangFactory, $filter) {
  var vm = this;

  //vm.chosenUnidadesNegocio = [];

  vm.sucursal = {};

  AuthorizationLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      vm.title = $filter('translate')('EDIT_SUCURSAL');
    });

  vm.unidadesNegocio = [];

  vm.cancel = cancel;

  vm.submit = submit;

  activate();

  function activate() {
    $rootScope.isProcessing = false;

  }

  function cancel() {
    $location.path("/empresas");
  }

  function getUnidadesNegocio() {
    return UnidadesNegocioFactory.all().$promise.then(function(response) {
      vm.unidadesNegocio = response;
      return vm.unidadesNegocio;
    });
  }

  function submit() {
    vm.submited = true;
    if($scope.EmpresasForm.$valid) {
      $rootScope.isProcessing = true;
      submitSucursal().then(function (sucursal) {
        //$log.info("sucursal actualizada");
        $location.path('/Empresas');
      });
    }

    function submitSucursal() {

      var sucursal = EmpresasFactory.create(vm.sucursal);
      return EmpresasFactory.save(sucursal);
    }
  }

  $scope.resource = 'Empresas';
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('CertificadoFormCtrl', CertificadoFormCtrl);

CertificadoFormCtrl.$inject = ['$rootScope', '$scope', '$location', '$state', 'baseurl',
'AuthorizationLangFactory', 'notify', '$filter', 'ParametrosFactory', 'EmpresaFactory', 'CertificadoFactory'];

function CertificadoFormCtrl($rootScope, $scope, $location, $state, baseurl,
  AuthorizationLangFactory, notify, $filter, ParametrosFactory, EmpresaFactory, CertificadoFactory) {
  var vm = this;

  vm.uiBlockuiConfig = {
    bloquear: false
  }

  vm.cancel = cancel;
  vm.submit = submit;
  vm.actualizarCertificado = actualizarCertificado;
  vm.verificarFecha = verificarFecha;

  vm.carpetapublic = baseurl.getPublicBaseUrl() + "/certificados/";

  activate();

  function activate() {
    vm.uiBlockuiConfig.bloquear =true;
    vm.view = true;
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      vm.title = $filter('translate')('ACTUALIZAR_CERTIFICADO');
      $rootScope.isProcessing = false;
      
      vm.certificado = {
        actualizarCertificado: false
      };
      //Preguntar por los parametros...
      EmpresaFactory.get(localStorage.getItem("EMPRESA")).$promise.then(function(response){
        vm.certificado.rucEmpresa = response.numeroDocumento;
        ParametrosFactory.getByCodigo("alias_cert_dig_"+ response.numeroDocumento).then(function(alias){
          vm.certificado.alias = alias.valor;
        });
        ParametrosFactory.getByCodigo("cod_cert_dig_"+ response.numeroDocumento).then(function(codigo){
          vm.certificado.codigo = codigo.valor;
        });
        ParametrosFactory.getByCodigo("nombre_cert_dig_"+ response.numeroDocumento).then(function(nombre){
          vm.certificado.nombre = nombre.valor;
        });
        ParametrosFactory.getByCodigo("fecha_venc_cert_dig_"+ response.numeroDocumento).then(function(fecha){
          if (fecha!=null){
            vm.certificado.fechaVencimiento = fecha.valor;
            if (typeof vm.certificado.fechaVencimiento === "string"){
              var partes = vm.certificado.fechaVencimiento.split("/");
              vm.certificado.fechaVencimiento = new Date(parseInt(partes[2]),parseInt(partes[1])-1, parseInt(partes[0]));
            }
          }
        });
        ParametrosFactory.getByCodigo("nombre_logo_empresa_"+ response.numeroDocumento).then(function(logo){
          vm.uploadOptions1 = {
            imageOnly: true,
            title: 'Logo'
          };
          if (logo!=null){
            vm.certificado.logo = logo.valor;
            vm.uploadOptions1.imagePath = vm.carpetapublic + vm.certificado.logo;
            vm.uploadOptions1.existe = true;
          }
          vm.uiBlockuiConfig.bloquear = false;
          
        });
      });
      

      vm.uploadOptions = {
      };
      
    });
  }

  function verificarFecha(){
    if (vm.certificado.fechaVencimiento == null){
      notify({
        message: $filter('translate')('FECHA_NO_EXISTE'),
        classes: 'alert-danger',
        position: 'right'
      });
      return false;
    }
    if (typeof vm.certificado.fechaVencimiento === "string"){
      var partes = vm.certificado.fechaVencimiento.split("/");
      vm.certificado.fechaVencimiento = new Date(parseInt(partes[2]),parseInt(partes[1])-1, parseInt(partes[0]));
    } 
    if (!isAfter(vm.certificado.fechaVencimiento, new Date())){
      notify({
        message: $filter('translate')('FECHA_VENCIMIENTO_MENOR_IGUAL'),
        classes: 'alert-danger',
        position: 'right'
      });
      vm.certificado.fechaVencimiento = null;
      return false;
    }
    return true;
  }

  function isAfter(fecha1, fecha2){
    if (fecha1.getYear() > fecha2.getYear()){
      return true;
    }else if (fecha1.getYear() == fecha2.getYear() && fecha1.getMonth() > fecha2.getMonth()){
      return true;
    }else if (fecha1.getMonth == fecha2.getMonth && fecha1.getDate() > fecha2.getDate()){
      return true;
    }
    return false;
  }

  function actualizarCertificado(){
    vm.view = false;
    vm.certificado = {
      actualizarCertificado: true,
      logo: vm.certificado.logo,
      rucEmpresa: vm.certificado.rucEmpresa
    }
  }

  function submit() {
    vm.submited = true;
    
    if($scope.CertificadoForm.$valid) {
      if (vm.certificado.actualizarCertificado){
        if (!verificarFecha()){
          vm.submited = false;
          return;
        } 
        
        if(vm.uploadOptions.flow == null){
          notify({
            message: $filter('translate')('ARCHIVO_OBLIGATORIO'),
            classes: 'alert-danger',
            position: 'right'
          });
          return;
        }else{
          if (vm.uploadOptions.flow.files.length > 1){
            notify({
              message: $filter('translate')('CERTIFICADO_SOLO_UNO'),
              classes: 'alert-danger',
              position: 'right'
            });
            vm.uploadOptions ={};
            return;
          }else{
            var partes = vm.uploadOptions.flow.files[0].name.split(".");
            if (partes[partes.length-1] != "pkcs12"){
              notify({
                message: $filter('translate')('CERTIFICADO_FORMATO_INCORRECTO'),
                classes: 'alert-danger',
                position: 'right'
              });
              vm.uploadOptions ={};
              return;
            }
          }
        }
  
        vm.certificado.nombre = vm.uploadOptions.flow.files[0].uniqueIdentifier + "-" + vm.uploadOptions.flow.files[0].name;
  
      }
      
      if (vm.uploadOptions1.flow != null){
        vm.certificado.logo = vm.uploadOptions1.flow.files[0].uniqueIdentifier + "-" + vm.uploadOptions1.flow.files[0].name;
      }

      $rootScope.isProcessing = true;
      CertificadoFactory.update(vm.certificado, vm.numeroDocumentoEmpresa).then(function (response) {
        notify({
          message: $filter('translate')('CERTIFICADO_ACTUALIZADO'),
          classes: 'alert-success',
          position: 'right'
        });
        $location.path('/');
      }, function(error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $location.path("/");
  }

  $scope.resource = 'Certificado'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}
/**
 * Created by miguel 10/03/2022
 */
 angular.module('qualita.authorization')
 .factory('CertificadoFactory', CertificadoFactory);
 
 CertificadoFactory.$inject = ['$resource', 'baseurl','$http'];
 
 function CertificadoFactory($resource, baseurl, $http) {
   var service = {
     get: get,
     update: update
   };
 
   var Certificado = $resource( baseurl.getBaseUrl()  + "/certificado/:numeroDocumento", {certificado: '@certificado'}, {
     update: {
       method: 'PUT'
     }
   });
 
   return service;
 
   function get(id, view) {
     return Certificado.get({id: id, view: view!=undefined ? view : 'CertificadoForm'});
   }

 
   function update(certificado, numeroDocumento) {
    return $http({
      url: baseurl.getBaseUrl() + "/certificado",
      method: "PUT",
      data: JSON.stringify(certificado)
    });
   }

 }
 
 
/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
.factory('EmpresaFactory', EmpresaFactory);

EmpresaFactory.$inject = ['$resource', 'baseurl', '$log'];

function EmpresaFactory($resource, baseurl, $log) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    allUsersWithoutAccess: allUsersWithoutAccess
  };

  var Empresa = $resource( baseurl.getBaseUrl()  + "/empresas/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'EmpresaList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'EmpresaList';
    }
    return Empresa.query(params);
  }

  function allUsersWithoutAccess() {
    return Empresa.usersWithoutAccess();
  }

  function get(id, view) {
    return Empresa.get({id: id, view: view!=undefined ? view : 'EmpresaForm'});
  }

  function create(attrs) {
    return new Empresa(attrs);
  }

  function save(Empresa) {
    return (Empresa.id) ? Empresa.$update() : Empresa.$save();
  }

  function remove(Empresa) {
    return Empresa.$remove();
  }
}


/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
  .factory('SucursalesFactory', SucursalesFactory);

SucursalesFactory.$inject = ['$resource', 'baseurl', '$http'];

function SucursalesFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    getTimbrado: getTimbrado
  };

  var Sucursal = $resource(baseurl.getBaseUrl() + "/sucursales/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseList';
    }
    return Sucursal.query(params);
  }

  function get(id, view) {
    return Sucursal.get({ id: id, view: view != undefined ? view : 'BaseList' });
  }

  function getTimbrado(id) {
    return $http({
      url: baseurl.getBaseUrl() + "/sucursales/timbrado/" + id ,
      method: "GET"
    });
  }

  function create(attrs) {
    return new Sucursal(attrs);
  }

  function save(sucursal) {
    return (sucursal.id) ? sucursal.$update() : sucursal.$save();
  }

  function remove(sucursal) {
    return sucursal.$remove();
  }
}

/**
 * Created by codiumsa on 2/10/15.
 */
angular.module('qualita.authorization')
.directive('authPermisos', ['PermisosFactory', '$modal', '$filter', function(PermisosFactory, $modal, $filter) {
    return {
      restrict: 'E',
      scope: {
        permisos: '=',
        model: '=',
        readOnly: '=',
        cantAMostrar: '=?cantInicial',
        mostrarForms: '=?mostrarFormularios'
      },
      templateUrl: 'qualita-components/authorization/views/directives/permisos.html',
      link: function(scope, el, attr) {
        scope.cantAMostrar = angular.isDefined(scope.cantAMostrar) ? scope.cantAMostar : 0;
        scope.mostrarForms = angular.isDefined(scope.mostrarForms) ? scope.mostrarForms : true;
        scope.showFiltros = false;
        scope.formsLimiter = scope.mostrarForms ? Infinity : 0;

        var permisosChunker = function(modulos) {
          _.forEach(modulos, function(modulo) {
            modulo.entidades = _.sortBy(modulo.entidades, function(entidad) { return - entidad.permisos.length; });
            modulo.entidadesFilas = _.chunk(modulo.entidades, 2);
          });
          modulos = _.sortBy(modulos, function(modulo) { return -modulo.entidades.length; })
          filas = _.chunk(modulos, 2);
          return filas;
        };

        scope.toggleAllPermisos = function(entidad) {
            entidad.cantMostrada = entidad.cantMostrada === scope.cantAMostrar ? Infinity : scope.cantAMostrar;
        };

        scope.toggleForms = function(modulo) {
          modulo.mostrarForms = modulo.mostrarForms ? false : true;
          modulo.formsLimiter = modulo.mostrarForms ? Infinity : 0;
        };

        scope.permisos.$promise.then(function(data) {
          scope.modulos = PermisosFactory.agruparPermisos(data);
          //le agregamos a cada grupo el atributo de cantMostrada
          _.forEach(scope.modulos, function(modulo) {
            modulo.entidades = _.map(modulo.entidades, function(elem) {
              return _.extend({}, elem, {cantMostrada: scope.cantAMostrar});
            });
          });
          _.forEach(scope.modulos, function(elem) {
            elem.mostrarForms = scope.mostrarForms;
            elem.formsLimiter = scope.formsLimiter;
          });
          scope.gruposPermisos = permisosChunker(scope.modulos);
        });

        var marcarManyGrupo = function(grupo) {
          var idsPermGrupo = [];
          _.forEach(grupo.entidades, function (entidad) {
            idsPermGrupo = _.union(idsPermGrupo, _.pluck(entidad.permisos, "id"));
          });
          scope.model.permisos = _.union(scope.model.permisos, idsPermGrupo);
        };

        var marcarManyEntidad = function(entidad) {
          var idsPermEntidad = _.pluck(entidad.permisos, "id");
          scope.model.permisos = _.union(scope.model.permisos, idsPermEntidad);
        };

        scope.marcarMany = function(grupo, $event) {
          if($event) $event.stopPropagation();
          if(grupo) {
            //HACK: Vemos si se trata efectivamente de un grupo o una entidad, ya que esta función admite ambos como
            //entrada. Hacemos la distinción viendo si el objeto entrada tiene la propiedad entidades o permisos.
            if(grupo.entidades) {
              scope.tituloModal = $filter('translate')('ADD_ALL_PERMISOS_Q');
              scope.mensajeModal = $filter('translate')('MARCARAN_ALL_PERMISOS_W');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: scope
              });

              scope.cancel = function() {
                modalInstance.dismiss('cancel');
              };

              scope.ok = function(itemId) {
                marcarManyGrupo(grupo);
                modalInstance.close();
              };
            }
            else if(grupo.permisos){
              marcarManyEntidad(grupo);
            }
          } else {
            scope.selectedItemId = grupo;
            scope.tituloModal = $filter('translate')('ADD_ALL_PERMISOS_Q');
            scope.mensajeModal = $filter('translate')('MARCARAN_ALL_PERMISOS');
            var modalInstance = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: scope
            });

            scope.cancel = function() {
              modalInstance.dismiss('cancel');
            };

            scope.ok = function(itemId) {
              scope.marcarManyCallback();
              modalInstance.close();
            }
          }
        };

        scope.marcarManyCallback = function() {
          var idsPerm = _.pluck(scope.permisos, "id");
          scope.model.permisos = idsPerm;
        };

        var desmarcarManyGrupo = function(grupo) {
          var idsPermGrupo = [];
          _.forEach(grupo.entidades, function (entidad) {
            idsPermGrupo = _.union(idsPermGrupo, _.pluck(entidad.permisos, "id"));
          });
          scope.model.permisos = _.difference(scope.model.permisos, idsPermGrupo);
        };

        var desmarcarManyEntidad = function(entidad) {
          var idsPermEntidad = _.pluck(entidad.permisos, "id");
          scope.model.permisos = _.difference(scope.model.permisos, idsPermEntidad);
        };

        scope.desmarcarMany = function(grupo, $event) {
          if($event) $event.stopPropagation();
          if(grupo) {
            //HACK: Vemos si se trata efectivamente de un grupo o una entidad, ya que esta función admite ambos como
            //entrada. Hacemos la distinción viendo si el objeto entrada tiene la propiedad entidades o permisos.
            if(grupo.entidades) {
              scope.tituloModal = $filter('translate')('LIMPIAR_SELECTED_PERMISOS_Q');
              scope.mensajeModal = $filter('translate')('DESASIGNARAN_ALL_PERMISOS');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: scope
              });

              scope.cancel = function() {
                modalInstance.dismiss('cancel');
              };

              scope.ok = function(itemId) {
                desmarcarManyGrupo(grupo);
                modalInstance.close();
              };
            }
            else if(grupo.permisos){
              desmarcarManyEntidad(grupo);
            }
          }
          else {
            scope.selectedItemId = grupo;
            scope.tituloModal = $filter('translate')('LIMPIAR_SELECTED_PERMISOS_Q');
            scope.mensajeModal = $filter('translate')('DESASIGNARAN_ALL_PERMISOS');
            var modalInstance = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: scope
            });

            scope.cancel = function() {
              modalInstance.dismiss('cancel');
            };

            scope.ok = function(itemId) {
              scope.desmarcarManyCallback();
              modalInstance.close();
            }
          }
        };

        scope.desmarcarManyCallback = function() {
          scope.model.permisos = null;
        };

        scope.toggleFiltros = function() {
          scope.showFiltros = !scope.showFiltros;
        }

        scope.filtroModulo = true;
        scope.filtroPermiso = true;
        scope.filtroFormulario = true;

      }
    }
  }]);

angular.module('qualita.authorization')
  .directive('countrySelect', ['$parse', function ($parse) {
    var countries = [
      "Afghanistan", "Aland Islands", "Albania", "Algeria", "American Samoa", "Andorra", "Angola",
      "Anguilla", "Antarctica", "Antigua And Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria",
      "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
      "Bermuda", "Bhutan", "Bolivia, Plurinational State of", "Bonaire, Sint Eustatius and Saba", "Bosnia and Herzegovina",
      "Botswana", "Bouvet Island", "Brazil",
      "British Indian Ocean Territory", "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia",
      "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China",
      "Christmas Island", "Cocos (Keeling) Islands", "Colombia", "Comoros", "Congo",
      "Congo, the Democratic Republic of the", "Cook Islands", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba",
      "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
      "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands (Malvinas)",
      "Faroe Islands", "Fiji", "Finland", "France", "French Guiana", "French Polynesia",
      "French Southern Territories", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece",
      "Greenland", "Grenada", "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinea",
      "Guinea-Bissau", "Guyana", "Haiti", "Heard Island and McDonald Islands", "Holy See (Vatican City State)",
      "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia", "Iran, Islamic Republic of", "Iraq",
      "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya",
      "Kiribati", "Korea, Democratic People's Republic of", "Korea, Republic of", "Kuwait", "Kyrgyzstan",
      "Lao People's Democratic Republic", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
      "Liechtenstein", "Lithuania", "Luxembourg", "Macao", "Macedonia, The Former Yugoslav Republic Of",
      "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique",
      "Mauritania", "Mauritius", "Mayotte", "Mexico", "Micronesia, Federated States of", "Moldova, Republic of",
      "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru",
      "Nepal", "Netherlands", "New Caledonia", "New Zealand", "Nicaragua", "Niger",
      "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands", "Norway", "Oman", "Pakistan", "Palau",
      "Palestinian Territory, Occupied", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
      "Pitcairn", "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion", "Romania", "Russian Federation",
      "Rwanda", "Saint Barthelemy", "Saint Helena, Ascension and Tristan da Cunha", "Saint Kitts and Nevis", "Saint Lucia",
      "Saint Martin (French Part)", "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
      "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
      "Sint Maarten (Dutch Part)", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
      "South Georgia and the South Sandwich Islands", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname",
      "Svalbard and Jan Mayen", "Swaziland", "Sweden", "Switzerland", "Syrian Arab Republic",
      "Taiwan, Province of China", "Tajikistan", "Tanzania, United Republic of", "Thailand", "Timor-Leste",
      "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
      "Turks and Caicos Islands", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
      "United States", "United States Minor Outlying Islands", "Uruguay", "Uzbekistan", "Vanuatu",
      "Venezuela, Bolivarian Republic of", "Viet Nam", "Virgin Islands, British", "Virgin Islands, U.S.",
      "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"
    ];

    return {
      restrict: 'E',
      scope: {
        val: '=ngModel'
      },
      template: '<select ng-model="val" class="form-control"> <option>' + countries.join('</option><option>') + '</option></select>',
      replace: false
    };
  }]);

/**
 * Created by codiumsa on 6/10/15.
 */
angular.module('qualita.authorization')
.filter('chunk', function() {
  return _.memoize(_.chunk);
});

/**
 * Created by miguel on 08082020.
 */
 angular.module('qualita.administracion', ['ngResource', 'checklist-model'])
 .config(
     ['$stateProvider', '$urlMatcherFactoryProvider', '$urlRouterProvider',
         function($stateProvider, $urlMatcherFactoryProvider, $urlRouterProvider) {
             $urlMatcherFactoryProvider.strictMode(false);
             $urlRouterProvider.otherwise('/main');

             $stateProvider
                 .state('app.clientes', {
                     url: '/clientes',
                     templateUrl: '../../views/template_base/index.html',
                     abstract: true,
                     ncyBreadcrumb: {
                         label: 'Clientes'
                     }
                 })
                 .state('app.clientes.list', {
                     url: '',
                     templateUrl: '../../views/template_base/list.html',
                     controller: 'ClientesIndexCtrl',
                     controllerAs: "vm",
                     ncyBreadcrumb: {
                         parent: 'app.clientes',
                         label: 'Clientes',
                         description: 'Listado de Clientes',
                         skip: true
                     }
                 })
                 .state('app.clientes.new', {
                     url: '/new',
                     templateUrl: 'qualita-components/administracion/views/clientes/form.html',
                     controller: 'ClientesFormCtrl',
                     controllerAs: "vm",
                     ncyBreadcrumb: {
                         parent: 'app.clientes',
                         label: 'Nuevo Cliente',
                         description: 'Creación de un nuevo cliente'
                     },
                     resolve: {
                         clientePrepService: function() { return; }
                     }
                 })
                 .state("app.clientes.carga", {
                    url: "/carga",
                    templateUrl: "qualita-components/administracion/views/clientes/carga.html",
                    controller: "ClientesCargaCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.clientes',
                        label: "Importar archivo",
                        description: "Importar archivo de clientes"
                    },
                    resolve: {
                        clientePrepService: function() { return; }
                    }
                })
                 .state('app.clientes.edit', {
                     url: '/:id/edit',
                     templateUrl: 'qualita-components/administracion/views/clientes/form.html',
                     controller: 'ClientesFormCtrl',
                     controllerAs: "vm",
                     ncyBreadcrumb: {
                         parent: 'app.clientes',
                         label: 'Editar Clientes',
                         description: 'Edición del cliente #'
                     },
                     resolve: {
                         clientePrepService: clientePrepService
                        }
                 })
                 .state('app.clientes.view', {
                     url: '/:id',
                     templateUrl: 'qualita-components/administracion/views/clientes/form.html',
                     controller: 'ClientesFormCtrl',
                     controllerAs: "vm",
                     ncyBreadcrumb: {
                         parent: 'app.clientes',
                         label: 'Ver Cliente',
                         description: 'Ver cliente #'
                     },
                     resolve: {
                         clientePrepService: clientePrepService
                     }
                 })
                 
                 .state('app.productos', {
                    url: '/productos',
                    templateUrl: '../../views/template_base/index.html',
                    abstract: true,
                    ncyBreadcrumb: {
                        label: 'Productos'
                    }
                })
                .state('app.productos.list', {
                    url: '',
                    templateUrl: '../../views/template_base/list.html',
                    controller: 'ProductosIndexCtrl',
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.productos',
                        label: 'productos',
                        description: 'Listado de Productos',
                        skip: true
                    }
                })
                .state('app.productos.new', {
                    url: '/new',
                    templateUrl: 'qualita-components/administracion/views/productos/form.html',
                    controller: 'ProductosFormCtrl',
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.productos',
                        label: 'Nuevo producto',
                        description: 'Creación de un nuevo producto'
                    },
                    resolve: {
                        productoPrepService: function() { return; }
                    }
                })
                .state('app.productos.edit', {
                    url: '/:id/edit',
                    templateUrl: 'qualita-components/administracion/views/productos/form.html',
                    controller: 'ProductosFormCtrl',
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.productos',
                        label: 'Editar Productos',
                        description: 'Edición del producto #'
                    },
                    resolve: {
                        productoPrepService: productoPrepService
                       }
                })
                .state('app.productos.view', {
                    url: '/:id',
                    templateUrl: 'qualita-components/administracion/views/productos/form.html',
                    controller: 'ProductosFormCtrl',
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.productos',
                        label: 'Ver Producto',
                        description: 'Ver producto #'
                    },
                    resolve: {
                        productoPrepService: productoPrepService
                    }
                }).state("app.productos.carga", {
                    url: "/carga",
                    templateUrl: "qualita-components/administracion/views/productos/carga.html",
                    controller: "ProductoCargaCtrl",
                    ncyBreadcrumb: {
                        label: "Importar archivo",
                        description: "Importar archivo de productos"
                    }
                })

         }]);

function clientePrepService(ClienteFactory, $stateParams) {
 return ClienteFactory.get($stateParams.id, "ClienteForm").$promise;
}
clientePrepService.$inject = ["ClienteFactory", "$stateParams"];

function productoPrepService(ProductoFactory, $stateParams) {
    return ProductoFactory.get($stateParams.id, "ProductoForm").$promise;
}
productoPrepService.$inject = ["ProductoFactory", "$stateParams"];
'use strict';


angular.module('qualita.administracion')
  .controller('ClientesIndexCtrl', ["ClienteFactory", "$scope", "AuthorizationLangFactory", "$filter", "filterFactory", "$state", function (ClienteFactory, $scope, AuthorizationLangFactory, $filter, filterFactory, $state) {

    var empresasFilter = {};
        empresasFilter.search = filterFactory.and([{
            path: "empresa.id",
            equals: localStorage.getItem('EMPRESA')
        }]).value();

    var nuevoCliente = function(){
      $state.go("app.clientes.new");
    }
    

    var defaultColumnOrder = ['id', 'codigo','tipoContribuyente','nombre','apellido', 'tipoDocumentoIdentidad', 'numeroDocumento', 'email'];
    AuthorizationLangFactory.getTranslations().then(function() {
        $scope.options = {
          'resource': 'clientes',
          'title': 'Clientes',
          'factory': ClienteFactory,
          'view': 'ClienteList',
          'defaultColumnOrder': defaultColumnOrder,
          'staticFilter': empresasFilter,
          'columns': [
            {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
            {'data': 'codigo', 'title': $filter('translate')('CODIGO')},
            {'data': 'tipoContribuyente', 'title': $filter('translate')('TIPO_CONTRIBUYENTE')},
            {'data': 'nombre', 'title': $filter('translate')('NOMBRE_CLIENTE')},
            {'data': 'apellido', 'title': $filter('translate')('APELLIDOS'),visible: false},
            {'data': 'numeroDocumento', 'title': $filter('translate')('NUMERO_DOCUMENTO')},
            {'data': 'email', 'title': $filter('translate')('EMAIL'), visible: false}
          ],
          'hasOptions' : true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraMenuOptions':
            [
              {
                  'title': 'CSV',
                  'icon': 'glyphicon glyphicon-export',
                  'showCondition': function() { return true; },
                  'action': function() {
                      var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                      CsvFactory.csv("Cliente", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                      .then(function(response) {
                        console.log("response de csv: ", response);
                        var fileName = response.data.fileName;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                      });
                    }
                },
                {
                  data: $filter('translate')('IMPORT_CLIENTES'),
                  title: "Carga Clientes",
                  icon: 'glyphicon glyphicon-upload',
                  showCondition: function () {
                    return true
                  },
                  action: function () {
                    $state.go("app.clientes.carga");
                  }
                }
            ]
          };
        });
        
  
      }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('ClientesFormCtrl', ClientesFormCtrl);

ClientesFormCtrl.$inject = ['$rootScope', '$scope', 'ClienteFactory', '$location', '$state',
'AuthorizationLangFactory', 'notify', '$filter', 'clientePrepService'];

function ClientesFormCtrl($rootScope, $scope, ClienteFactory, $location, $state,
  AuthorizationLangFactory, notify, $filter, clientePrepService) {
  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;

  activate();

  vm.tiposContribuyente = [
    {"id": 0, "descripcion": "No contribuyente"},
    {"id": 1, "descripcion": "Física"},
    {"id": 2, "descripcion": "Jurídica"}];

  vm.tiposDocumentos = [
    {"id": 1, "descripcion": "Cédula paraguaya"},
    {"id": 2, "descripcion": "Pasaporte"},
    {"id": 3, "descripcion": "Cédula extranjera"},
    {"id": 4, "descripcion": "Carnet de residencia"},
    {"id": 5, "descripcion": "Innominado"},
    {"id": 6, "descripcion": "Tarjeta Diplomática de exoneración fiscal"}];

  vm.paises = [{"id":1,"descripcion":"Afganistán","codigo":"AFG"},
  {"id":2,"descripcion":"Åland","codigo":"ALA"},
  {"id":3,"descripcion":"Albania","codigo":"ALB"},
  {"id":4,"descripcion":"Alemania","codigo":"DEU"},
  {"id":5,"descripcion":"Andorra","codigo":"AND"},
  {"id":6,"descripcion":"Angola","codigo":"AGO"},
  {"id":7,"descripcion":"Anguila","codigo":"AIA"},
  {"id":8,"descripcion":"Antártida","codigo":"ATA"},
  {"id":9,"descripcion":"Antigua y Barbuda","codigo":"ATG"},
  {"id":10,"descripcion":"Arabia Saudita","codigo":"SAU"},
  {"id":11,"descripcion":"Argelia","codigo":"DZA"},
  {"id":12,"descripcion":"Argentina","codigo":"ARG"},
  {"id":13,"descripcion":"Armenia","codigo":"ARM"},
  {"id":14,"descripcion":"Aruba","codigo":"ABW"},
  {"id":15,"descripcion":"Australia","codigo":"AUS"},
  {"id":16,"descripcion":"Austria","codigo":"AUT"},
  {"id":17,"descripcion":"Azerbaiyán","codigo":"AZE"},
  {"id":18,"descripcion":"Bahamas","codigo":"BHS"},
  {"id":19,"descripcion":"Bangladés","codigo":"BGD"},
  {"id":20,"descripcion":"Barbados","codigo":"BRB"},
  {"id":21,"descripcion":"Baréin","codigo":"BHR"},
  {"id":22,"descripcion":"Bélgica","codigo":"BEL"},
  {"id":23,"descripcion":"Belice","codigo":"BLZ"},
  {"id":24,"descripcion":"Benín","codigo":"BEN"},
  {"id":25,"descripcion":"Bermudas","codigo":"BMU"},
  {"id":26,"descripcion":"Bielorrusia","codigo":"BLR"},
  {"id":27,"descripcion":"Bolivia","codigo":"BOL"},
  {"id":28,"descripcion":"Bonaire, San Eustaquio y Saba","codigo":"BES"},
  {"id":29,"descripcion":"Bosnia y Herzegovina","codigo":"BIH"},
  {"id":30,"descripcion":"Botsuana","codigo":"BWA"},
  {"id":31,"descripcion":"Brasil","codigo":"BRA"},
  {"id":32,"descripcion":"Brunéi","codigo":"BRN"},
  {"id":33,"descripcion":"Bulgaria","codigo":"BGR"},
  {"id":34,"descripcion":"Burkina Faso","codigo":"BFA"},
  {"id":35,"descripcion":"Burundi","codigo":"BDI"},
  {"id":36,"descripcion":"Bután","codigo":"BTN"},
  {"id":37,"descripcion":"Cabo Verde","codigo":"CPV"},
  {"id":38,"descripcion":"Camboya","codigo":"KHM"},
  {"id":39,"descripcion":"Camerún","codigo":"CMR"},
  {"id":40,"descripcion":"Canadá","codigo":"CAN"},
  {"id":41,"descripcion":"Catar","codigo":"QAT"},
  {"id":42,"descripcion":"Chad","codigo":"TCD"},
  {"id":43,"descripcion":"Chile","codigo":"CHL"},
  {"id":44,"descripcion":"China","codigo":"CHN"},
  {"id":45,"descripcion":"Chipre","codigo":"CYP"},
  {"id":46,"descripcion":"Colombia","codigo":"COL"},
  {"id":47,"descripcion":"Comoras","codigo":"COM"},
  {"id":48,"descripcion":"Corea del Norte","codigo":"PRK"},
  {"id":49,"descripcion":"Corea del Sur","codigo":"KOR"},
  {"id":50,"descripcion":"Costa de Marfil","codigo":"CIV"},
  {"id":51,"descripcion":"Costa Rica","codigo":"CRI"},
  {"id":52,"descripcion":"Croacia","codigo":"HRV"},
  {"id":53,"descripcion":"Cuba","codigo":"CUB"},
  {"id":54,"descripcion":"Curazao","codigo":"CUW"},
  {"id":55,"descripcion":"Dinamarca","codigo":"DNK"},
  {"id":56,"descripcion":"Dominica","codigo":"DMA"},
  {"id":57,"descripcion":"Ecuador","codigo":"ECU"},
  {"id":58,"descripcion":"Egipto","codigo":"EGY"},
  {"id":59,"descripcion":"El Salvador","codigo":"SLV"},
  {"id":60,"descripcion":"Emiratos Árabes Unidos","codigo":"ARE"},
  {"id":61,"descripcion":"Eritrea","codigo":"ERI"},
  {"id":62,"descripcion":"Eslovaquia","codigo":"SVK"},
  {"id":63,"descripcion":"Eslovenia","codigo":"SVN"},
  {"id":64,"descripcion":"España","codigo":"ESP"},
  {"id":65,"descripcion":"Estados Unidos","codigo":"USA"},
  {"id":66,"descripcion":"Estonia","codigo":"EST"},
  {"id":67,"descripcion":"Etiopía","codigo":"ETH"},
  {"id":68,"descripcion":"Filipinas","codigo":"PHL"},
  {"id":69,"descripcion":"Finlandia","codigo":"FIN"},
  {"id":70,"descripcion":"Fiyi","codigo":"FJI"},
  {"id":71,"descripcion":"Francia","codigo":"FRA"},
  {"id":72,"descripcion":"Gabón","codigo":"GAB"},
  {"id":73,"descripcion":"Gambia","codigo":"GMB"},
  {"id":74,"descripcion":"Georgia","codigo":"GEO"},
  {"id":75,"descripcion":"Ghana","codigo":"GHA"},
  {"id":76,"descripcion":"Gibraltar","codigo":"GIB"},
  {"id":77,"descripcion":"Granada","codigo":"GRD"},
  {"id":78,"descripcion":"Grecia","codigo":"GRC"},
  {"id":79,"descripcion":"Groenlandia","codigo":"GRL"},
  {"id":80,"descripcion":"Guadalupe","codigo":"GLP"},
  {"id":81,"descripcion":"Guam","codigo":"GUM"},
  {"id":82,"descripcion":"Guatemala","codigo":"GTM"},
  {"id":83,"descripcion":"Guayana Francesa","codigo":"GUF"},
  {"id":84,"descripcion":"Guernsey","codigo":"GGY"},
  {"id":85,"descripcion":"Guinea","codigo":"GIN"},
  {"id":86,"descripcion":"Guinea-Bisáu","codigo":"GNB"},
  {"id":87,"descripcion":"Guinea Ecuatorial","codigo":"GNQ"},
  {"id":88,"descripcion":"Guyana","codigo":"GUY"},
  {"id":89,"descripcion":"Haití","codigo":"HTI"},
  {"id":90,"descripcion":"Honduras","codigo":"HND"},
  {"id":91,"descripcion":"Hong Kong","codigo":"HKG"},
  {"id":92,"descripcion":"Hungría","codigo":"HUN"},
  {"id":93,"descripcion":"India","codigo":"IND"},
  {"id":94,"descripcion":"Indonesia","codigo":"IDN"},
  {"id":95,"descripcion":"Irak","codigo":"IRQ"},
  {"id":96,"descripcion":"Irán","codigo":"IRN"},
  {"id":97,"descripcion":"Irlanda","codigo":"IRL"},
  {"id":98,"descripcion":"Isla Bouvet","codigo":"BVT"},
  {"id":99,"descripcion":"Isla de Man","codigo":"IMN"},
  {"id":100,"descripcion":"Isla de Navidad","codigo":"CXR"},
  {"id":101,"descripcion":"Islandia","codigo":"ISL"},
  {"id":102,"descripcion":"Islas Caimán","codigo":"CYM"},
  {"id":103,"descripcion":"Islas Cocos","codigo":"CCK"},
  {"id":104,"descripcion":"Islas Cook","codigo":"COK"},
  {"id":105,"descripcion":"Islas Feroe","codigo":"FRO"},
  {"id":106,"descripcion":"Islas Georgias del Sur y Sandwich del Sur","codigo":"SGS"},
  {"id":107,"descripcion":"Islas Heard y McDonald","codigo":"HMD"},
  {"id":108,"descripcion":"Islas Malvinas","codigo":"FLK"},
  {"id":109,"descripcion":"Islas Marianas del Norte","codigo":"MNP"},
  {"id":110,"descripcion":"Islas Marshall","codigo":"MHL"},
  {"id":111,"descripcion":"Islas Pitcairn","codigo":"PCN"},
  {"id":112,"descripcion":"Islas Salomón","codigo":"SLB"},
  {"id":113,"descripcion":"Islas Turcas y Caicos","codigo":"TCA"},
  {"id":114,"descripcion":"Islas Ultramarinas Menores de los Estados Unidos","codigo":"UMI"},
  {"id":115,"descripcion":"Islas Vírgenes Británicas","codigo":"VGB"},
  {"id":116,"descripcion":"Islas Vírgenes de los Estados Unidos","codigo":"VIR"},
  {"id":117,"descripcion":"Israel","codigo":"ISR"},
  {"id":118,"descripcion":"Italia","codigo":"ITA"},
  {"id":119,"descripcion":"Jamaica","codigo":"JAM"},
  {"id":120,"descripcion":"Japón","codigo":"JPN"},
  {"id":121,"descripcion":"Jersey","codigo":"JEY"},
  {"id":122,"descripcion":"Jordania","codigo":"JOR"},
  {"id":123,"descripcion":"Kazajistán","codigo":"KAZ"},
  {"id":124,"descripcion":"Kenia","codigo":"KEN"},
  {"id":125,"descripcion":"Kirguistán","codigo":"KGZ"},
  {"id":126,"descripcion":"Kiribati","codigo":"KIR"},
  {"id":127,"descripcion":"Kuwait","codigo":"KWT"},
  {"id":128,"descripcion":"Laos","codigo":"LAO"},
  {"id":129,"descripcion":"Lesoto","codigo":"LSO"},
  {"id":130,"descripcion":"Letonia","codigo":"LVA"},
  {"id":131,"descripcion":"Líbano","codigo":"LBN"},
  {"id":132,"descripcion":"Liberia","codigo":"LBR"},
  {"id":133,"descripcion":"Libia","codigo":"LBY"},
  {"id":134,"descripcion":"Liechtenstein","codigo":"LIE"},
  {"id":135,"descripcion":"Lituania","codigo":"LTU"},
  {"id":136,"descripcion":"Luxemburgo","codigo":"LUX"},
  {"id":137,"descripcion":"Macao","codigo":"MAC"},
  {"id":138,"descripcion":"Macedonia del Norte","codigo":"MKD"},
  {"id":139,"descripcion":"Madagascar","codigo":"MDG"},
  {"id":140,"descripcion":"Malasia","codigo":"MYS"},
  {"id":141,"descripcion":"Malaui","codigo":"MWI"},
  {"id":142,"descripcion":"Maldivas","codigo":"MDV"},
  {"id":143,"descripcion":"Malí","codigo":"MLI"},
  {"id":144,"descripcion":"Malta","codigo":"MLT"},
  {"id":145,"descripcion":"Marruecos","codigo":"MAR"},
  {"id":146,"descripcion":"Martinica","codigo":"MTQ"},
  {"id":147,"descripcion":"Mauricio","codigo":"MUS"},
  {"id":148,"descripcion":"Mauritania","codigo":"MRT"},
  {"id":149,"descripcion":"Mayotte","codigo":"MYT"},
  {"id":150,"descripcion":"México","codigo":"MEX"},
  {"id":151,"descripcion":"Micronesia","codigo":"FSM"},
  {"id":152,"descripcion":"Moldavia","codigo":"MDA"},
  {"id":153,"descripcion":"Mónaco","codigo":"MCO"},
  {"id":154,"descripcion":"Mongolia","codigo":"MNG"},
  {"id":155,"descripcion":"Montenegro","codigo":"MNE"},
  {"id":156,"descripcion":"Montserrat","codigo":"MSR"},
  {"id":157,"descripcion":"Mozambique","codigo":"MOZ"},
  {"id":158,"descripcion":"Birmania","codigo":"MMR"},
  {"id":159,"descripcion":"Namibia","codigo":"NAM"},
  {"id":160,"descripcion":"Nauru","codigo":"NRU"},
  {"id":161,"descripcion":"Nepal","codigo":"NPL"},
  {"id":162,"descripcion":"Nicaragua","codigo":"NIC"},
  {"id":163,"descripcion":"Níger","codigo":"NER"},
  {"id":164,"descripcion":"Nigeria","codigo":"NGA"},
  {"id":165,"descripcion":"Niue","codigo":"NIU"},
  {"id":166,"descripcion":"Isla Norfolk","codigo":"NFK"},
  {"id":167,"descripcion":"Noruega","codigo":"NOR"},
  {"id":168,"descripcion":"Nueva Caledonia","codigo":"NCL"},
  {"id":169,"descripcion":"Nueva Zelanda","codigo":"NZL"},
  {"id":170,"descripcion":"Omán","codigo":"OMN"},
  {"id":171,"descripcion":"Países Bajos","codigo":"NLD"},
  {"id":172,"descripcion":"Pakistán","codigo":"PAK"},
  {"id":173,"descripcion":"Palaos","codigo":"PLW"},
  {"id":174,"descripcion":"Palestina","codigo":"PSE"},
  {"id":175,"descripcion":"Panamá","codigo":"PAN"},
  {"id":176,"descripcion":"Papúa Nueva Guinea","codigo":"PNG"},
  {"id":177,"descripcion":"Paraguay","codigo":"PRY"},
  {"id":178,"descripcion":"Perú","codigo":"PER"},
  {"id":179,"descripcion":"Polinesia Francesa","codigo":"PYF"},
  {"id":180,"descripcion":"Polonia","codigo":"POL"},
  {"id":181,"descripcion":"Portugal","codigo":"PRT"},
  {"id":182,"descripcion":"Puerto Rico","codigo":"PRI"},
  {"id":183,"descripcion":"Reino Unido","codigo":"GBR"},
  {"id":184,"descripcion":"República Árabe Saharaui Democrática","codigo":"ESH"},
  {"id":185,"descripcion":"República Centroafricana","codigo":"CAF"},
  {"id":186,"descripcion":"República Checa","codigo":"CZE"},
  {"id":187,"descripcion":"República del Congo","codigo":"COG"},
  {"id":188,"descripcion":"República Democrática del Congo","codigo":"COD"},
  {"id":189,"descripcion":"República Dominicana","codigo":"DOM"},
  {"id":190,"descripcion":"Reunión","codigo":"REU"},
  {"id":191,"descripcion":"Ruanda","codigo":"RWA"},
  {"id":192,"descripcion":"Rumania","codigo":"ROU"},
  {"id":193,"descripcion":"Rusia","codigo":"RUS"},
  {"id":194,"descripcion":"Samoa","codigo":"WSM"},
  {"id":195,"descripcion":"Samoa Americana","codigo":"ASM"},
  {"id":196,"descripcion":"San Bartolomé","codigo":"BLM"},
  {"id":197,"descripcion":"San Cristóbal y Nieves","codigo":"KNA"},
  {"id":198,"descripcion":"San Marino","codigo":"SMR"},
  {"id":199,"descripcion":"San Martín","codigo":"MAF"},
  {"id":200,"descripcion":"San Pedro y Miquelón","codigo":"SPM"},
  {"id":201,"descripcion":"San Vicente y las Granadinas","codigo":"VCT"},
  {"id":202,"descripcion":"Santa Elena, Ascensión y Tristán de Acuña","codigo":"SHN"},
  {"id":203,"descripcion":"Santa Lucía","codigo":"LCA"},
  {"id":204,"descripcion":"Santo Tomé y Príncipe","codigo":"STP"},
  {"id":205,"descripcion":"Senegal","codigo":"SEN"},
  {"id":206,"descripcion":"Serbia","codigo":"SRB"},
  {"id":207,"descripcion":"Seychelles","codigo":"SYC"},
  {"id":208,"descripcion":"Sierra Leona","codigo":"SLE"},
  {"id":209,"descripcion":"Singapur","codigo":"SGP"},
  {"id":210,"descripcion":"San Martín","codigo":"SXM"},
  {"id":211,"descripcion":"Siria","codigo":"SYR"},
  {"id":212,"descripcion":"Somalia","codigo":"SOM"},
  {"id":213,"descripcion":"Sri Lanka","codigo":"LKA"},
  {"id":214,"descripcion":"Suazilandia","codigo":"SWZ"},
  {"id":215,"descripcion":"Sudáfrica","codigo":"ZAF"},
  {"id":216,"descripcion":"Sudán","codigo":"SDN"},
  {"id":217,"descripcion":"Sudán del Sur","codigo":"SSD"},
  {"id":218,"descripcion":"Suecia","codigo":"SWE"},
  {"id":219,"descripcion":"Suiza","codigo":"CHE"},
  {"id":220,"descripcion":"Surinam","codigo":"SUR"},
  {"id":221,"descripcion":"Svalbard y Jan Mayen","codigo":"SJM"},
  {"id":222,"descripcion":"Tailandia","codigo":"THA"},
  {"id":223,"descripcion":"Taiwán (República de China)","codigo":"TWN"},
  {"id":224,"descripcion":"Tanzania","codigo":"TZA"},
  {"id":225,"descripcion":"Tayikistán","codigo":"TJK"},
  {"id":226,"descripcion":"Territorio Británico del Océano Índico","codigo":"IOT"},
  {"id":227,"descripcion":"Tierras Australes y Antárticas Francesas","codigo":"ATF"},
  {"id":228,"descripcion":"Timor Oriental","codigo":"TLS"},
  {"id":229,"descripcion":"Togo","codigo":"TGO"},
  {"id":230,"descripcion":"Tokelau","codigo":"TKL"},
  {"id":231,"descripcion":"Tonga","codigo":"TON"},
  {"id":232,"descripcion":"Trinidad y Tobago","codigo":"TTO"},
  {"id":233,"descripcion":"Túnez","codigo":"TUN"},
  {"id":234,"descripcion":"Turkmenistán","codigo":"TKM"},
  {"id":235,"descripcion":"Turquía","codigo":"TUR"},
  {"id":236,"descripcion":"Tuvalu","codigo":"TUV"},
  {"id":237,"descripcion":"Ucrania","codigo":"UKR"},
  {"id":238,"descripcion":"Uganda","codigo":"UGA"},
  {"id":239,"descripcion":"Uruguay","codigo":"URY"},
  {"id":240,"descripcion":"Uzbekistán","codigo":"UZB"},
  {"id":241,"descripcion":"Vanuatu","codigo":"VUT"},
  {"id":242,"descripcion":"Ciudad del Vaticano","codigo":"VAT"},
  {"id":243,"descripcion":"Venezuela","codigo":"VEN"},
  {"id":244,"descripcion":"Vietnam","codigo":"VNM"},
  {"id":245,"descripcion":"Wallis y Futuna","codigo":"WLF"},
  {"id":246,"descripcion":"Yemen","codigo":"YEM"},
  {"id":247,"descripcion":"Yibuti","codigo":"DJI"},
  {"id":248,"descripcion":"Zambia","codigo":"ZMB"},
  {"id":249,"descripcion":"Zimbabue","codigo":"ZWE"}
  ]

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      if($state.is("app.clientes.new")) {
        activateNew();
      } else if($state.is("app.clientes.edit")) {
        activateEdit();
      } else if($state.is("app.clientes.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVO_CLIENTE');
    vm.cliente = {};
    vm.cliente.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === "PRY";
    });
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDITAR_CLIENTE');
    vm.cliente = clientePrepService;
    if (vm.cliente.tipoContribuyente == "Física"){
      vm.cliente.tipoContribuyente = vm.tiposContribuyente[1];
    }else if (vm.cliente.tipoContribuyente == "Jurídica"){
        vm.cliente.tipoContribuyente = vm.tiposContribuyente[2];
    }else{
      vm.cliente.tipoContribuyente = vm.tiposContribuyente[0];
    }
    if (vm.cliente.codigoPais != null){
      vm.cliente.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === vm.cliente.codigoPais;
      });
    }else{
      vm.cliente.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === "PRY";
      });
    }
    if (vm.cliente.tipoDocumentoReceptor != null){
      vm.cliente.tipoDocumentoReceptor = _.find(vm.tiposDocumentos, function(tipoDocumento){
        return tipoDocumento.id === vm.cliente.tipoDocumentoReceptor;
      });
    }

    $scope.entidadId=vm.cliente.id;
    $scope.entidad="Cliente";
  }

  function activateView() {
    vm.title = $filter('translate')('VER_CLIENTE');
    vm.cliente = clientePrepService;
    if (vm.cliente.tipoContribuyente == "Física"){
      vm.cliente.tipoContribuyente = vm.tiposContribuyente[1];
    }else if (vm.cliente.tipoContribuyente == "Jurídica"){
        vm.cliente.tipoContribuyente = vm.tiposContribuyente[2];
    }else{
      vm.cliente.tipoContribuyente = vm.tiposContribuyente[0];
    }
    if (vm.cliente.codigoPais != null){
      vm.cliente.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === vm.cliente.codigoPais;
      });
    }else{
      vm.cliente.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === "PRY";
      });
    }
    if (vm.cliente.tipoDocumentoReceptor != null){
      vm.cliente.tipoDocumentoReceptor = _.find(vm.tiposDocumentos, function(tipoDocumento){
        return tipoDocumento.id === vm.cliente.tipoDocumentoReceptor;
      });
    }
    $scope.entidadId=vm.cliente.id;
    $scope.entidad="Cliente";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;
    if($scope.ClienteForm.$valid) {
      $rootScope.isProcessing = true;
      var idTipoContribuyente = vm.cliente.tipoContribuyente.id;
      vm.cliente.tipoContribuyente = vm.cliente.tipoContribuyente.descripcion;
      if (idTipoContribuyente != 0){
        vm.cliente.tipoDocumentoReceptor = null;
      }
      var idCodigoPais = vm.cliente.codigoPais.id;
      var descripcionPais = vm.cliente.codigoPais.descripcion;
      var idCodigoTipoDocumento = null;
      if (vm.cliente.tipoDocumentoReceptor != null){
        idCodigoTipoDocumento = vm.cliente.tipoDocumentoReceptor.id;
        vm.cliente.tipoDocumentoReceptor = vm.cliente.tipoDocumentoReceptor.id;
      }
      vm.cliente.codigoPais = vm.cliente.codigoPais.codigo;
      submitCliente().then(function () {
        $location.path('/clientes');
      }, function(error) {
        $rootScope.isProcessing = false;
        vm.cliente.tipoContribuyente = {
            id: idTipoContribuyente,
            descripcion: vm.cliente.tipoContribuyente
        };
        vm.cliente.codigoPais = {
          id: idCodigoPais,
          codigoPais: vm.cliente.codigoPais,
          descripcion: descripcionPais
        };
        vm.cliente.tipoDocumentoReceptor = {
          id: idCodigoTipoDocumento
        }
        errorHandler(error);
      });
    }

    function submitCliente() {
      var cliente = ClienteFactory.create(vm.cliente);
      return ClienteFactory.save(cliente);
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $location.path("/clientes");
  }

  $scope.resource = 'Cliente'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ClientesCargaCtrl
 * Controller of the rasApp
 */
angular.module('qualita.administracion')
  .controller('ClientesCargaCtrl', ["$http", "$rootScope", "$location", "$scope", "formFactory", "$window", "baseurl", "usSpinnerService", "notify", "$state", "ClienteFactory", function ($http ,$rootScope, $location,$scope, formFactory, $window, baseurl, usSpinnerService,notify,$state, ClienteFactory) {

    $scope.title = 'Importar clientes';

    $rootScope.isProcessing = false;

    $scope.schema = {
      type: 'object',
      title: 'Cliente',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

     var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/clienteUpload'
      },
      uploader: {}
    };

    $scope.form = [
      fileFormProperty
    ];

    $scope.options = formFactory.defaultOptions();

    $scope.resource = 'cliente';

    $scope.model = {};

    $scope.position = 0;

    $scope.formSent = false;

    $scope.submitButton = 'Actualizar datos';

    $scope.startSpin = function(){
        usSpinnerService.spin('spinner-1');
    }

    $scope.stopSpin = function(){
        usSpinnerService.stop('spinner-1');
    }
    var vm = this;
    $scope.submit = function(form) {
      var flow = fileFormProperty.uploader.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        $scope.model.file = flowFile.name;
        $scope.$broadcast('schemaFormValidate');
        var model = ClienteFactory.create($scope.model);
        $scope.formSent = true;
        $scope.startSpin();

        $rootScope.isProcessing = true;
        ClienteFactory.carga($scope.model.file).then(function(data){
          console.log("success");
          if(data.file === null || data.file === undefined) {
            $scope.stopSpin();
            $scope.formSent = false;
            notify({
              message: "Archivo procesado con éxito",
              classes: 'alert-success',
              position: 'right'
            });
            $state.go("app.clientes.list");
          } else {
              $scope.stopSpin();
              $scope.formSent = false;
              $state.go("app.clientes.list");
          }
        }, function(error){
          $rootScope.isProcessing = false;
          $scope.stopSpin();
		      $scope.formSent = false;
          notify({
            message: error.data[0].message,
            classes: 'alert-danger',
            position: 'right'
          });
        });
    };

    $scope.cancel = function(form) {
    }
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('ProductosFormCtrl', ProductosFormCtrl);

ProductosFormCtrl.$inject = ['$rootScope', '$scope', 'ProductoFactory', '$location', '$state',
'AuthorizationLangFactory', 'notify', '$filter', 'productoPrepService'];

function ProductosFormCtrl($rootScope, $scope, ProductoFactory, $location, $state,
  AuthorizationLangFactory, notify, $filter, productoPrepService) {
  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;

  activate();

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      if($state.is("app.productos.new")) {
        activateNew();
      } else if($state.is("app.productos.edit")) {
        activateEdit();
      } else if($state.is("app.productos.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVO_PRODUCTO');
    vm.producto = {};
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDITAR_PRODUCTO');
    vm.producto = productoPrepService;
    $scope.entidadId=vm.producto.id;
    $scope.entidad="Producto";
  }

  function activateView() {
    vm.title = $filter('translate')('VER_PRODUCTO');
    vm.producto = productoPrepService;
    $scope.entidadId=vm.producto.id;
    $scope.entidad="Producto";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;
    if($scope.ProductoForm.$valid) {
      $rootScope.isProcessing = true;
      submitProducto().then(function () {
        $location.path('/productos');
      }, function(error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    }

    function submitProducto() {
      var producto = ProductoFactory.create(vm.producto);
      return ProductoFactory.save(producto);
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $location.path("/Productos");
  }

  $scope.resource = 'Productos'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';


angular.module('qualita.administracion')
  .controller('ProductosIndexCtrl', ["ProductoFactory", "$scope", "AuthorizationLangFactory", "$filter", "filterFactory", "$state", function (ProductoFactory, $scope, AuthorizationLangFactory, $filter, filterFactory, $state) {

    var empresasFilter = {};
    empresasFilter.search = filterFactory.and([{
        path: "empresa.id",
        equals: localStorage.getItem('EMPRESA')
    }]).value();

    var nuevoProducto = function(){
      $state.go("app.productos.new");
    }

    var defaultColumnOrder = ['codigo', 'nombre','descripcion'];
    AuthorizationLangFactory.getTranslations().then(function() {
        $scope.options = {
          'resource': 'productos',
          'title': 'Productos',
          'factory': ProductoFactory,
          'view': 'ProductoList',
          'defaultColumnOrder': defaultColumnOrder,
          'staticFilter': empresasFilter,
          'columns': [
            {'data': 'codigo', 'title': $filter('translate')('CODE')},
            {'data': 'nombre', 'title': $filter('translate')('NOMBRE_PRODUCTO')},
            {'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION')}
          ],
          'hasOptions' : true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraMenuOptions':
            [
              {
                  'title': 'CSV',
                  'icon': 'glyphicon glyphicon-export',
                  'showCondition': function() { return true; },
                  'action': function() {
                      var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                      CsvFactory.csv("Producto", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                      .then(function(response) {
                        console.log("response de csv: ", response);
                        var fileName = response.data.fileName;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                      });
                    }
                }
            ]
          };
        });
       
      
    
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.administracion')
  .controller('ProductoCargaCtrl', ["$http", "$rootScope", "$location", "$scope", "ProductoCargaFactory", "formFactory", "$window", "baseurl", "usSpinnerService", "notify", "listaPrecioVentaPrepService", "$state", "PreviousState", function ($http ,$rootScope, $location,$scope, ProductoCargaFactory, formFactory, $window, baseurl, usSpinnerService,notify, listaPrecioVentaPrepService,$state,PreviousState) {

    $scope.title = 'Producto';

    $rootScope.isProcessing = false;

    $scope.schema = ProductoCargaFactory.schema();

    $scope.form = ProductoCargaFactory.form();

    $scope.options = formFactory.defaultOptions();

    $scope.resource = 'producto';

    $scope.model = {};

    $scope.position = 0;

    $scope.submitButton = 'Actualizar datos';

    $scope.formSent = false;

    $scope.startSpin = function(){
        usSpinnerService.spin('spinner-1');
    }

    $scope.stopSpin = function(){
        usSpinnerService.stop('spinner-1');
    }
    var vm = this;
    vm.listaPrecioVenta = listaPrecioVentaPrepService;
    vm.previousState=PreviousState;
    $scope.submit = function(form) {
      var flow = ProductoCargaFactory.getFileFormProperty().uploader.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        $scope.model.file = flowFile.name;
        $scope.$broadcast('schemaFormValidate');
        var model = ProductoCargaFactory.create($scope.model);
        $scope.formSent = true;
        $scope.startSpin();

        $rootScope.isProcessing = true;
        console.log(vm.listaPrecioVenta);
        ProductoCargaFactory.carga($scope.model.file, vm.listaPrecioVenta.id).then(function(data){
          console.log("succes");
          if(data.file === null || data.file === undefined) {
            ProductoCargaFactory.setData(data);
            $scope.stopSpin();
            $scope.formSent = false;
            $state.go(vm.previousState.Name, { id: vm.listaPrecioVenta.id });
          } else {
              $scope.stopSpin();
              $scope.formSent = false;
              $state.go(vm.previousState.Name, { id: vm.listaPrecioVenta.id });
          }
        }, function(error){
          $rootScope.isProcessing = false;
          $scope.stopSpin();
		      $scope.formSent = false;
          notify({
            message: error.data[0].message,
            classes: 'alert-danger',
            position: 'right'
          });
        });
    };

    $scope.cancel = function(form) {
      $state.go(vm.previousState.Name, { id: vm.previousState.Params.id });
    }
  }]);

/**
 * Created by miguel on 10/08/2022.
 */
 angular.module('qualita.administracion')
 .factory('ClienteFactory', ClienteFactory);
 
 ClienteFactory.$inject = ['$resource', 'baseurl', '$log','$http'];
 
 function ClienteFactory($resource, baseurl, $log, $http) {
   var service = {
     all: all,
     get: get,
     create: create,
     save: save,
     remove: remove,
     allUsersWithoutAccess: allUsersWithoutAccess,
     carga: carga
   };
 
   var Cliente = $resource( baseurl.getBaseUrl()  + "/clientes/:id", {id: '@id'}, {
     update: {
       method: 'PUT'
     }
   });
 
   return service;
 
   function all(params, view) {
     if (params) {
       params.view = view!=undefined ? view : 'ClienteList';
     } else {
       params = [];
       params.view = view!=undefined ? view : 'ClienteList';
     }
     return Cliente.query(params);
   }
 
   function allUsersWithoutAccess() {
     return Cliente.usersWithoutAccess();
   }
 
   function get(id, view) {
     return Cliente.get({id: id, view: view!=undefined ? view : 'ClienteForm'});
   }
 
   function create(attrs) {
     return new Cliente(attrs);
   }
 
   function save(cliente) {
     return (cliente.id) ? cliente.$update() : $http({
      url: baseurl.getBaseUrl() + "/clientes/persist",
      method: "POST",
      data: JSON.stringify(cliente)
    });
   }
 
   function remove(cliente) {
     return cliente.$remove();
   }

   function carga(name, id) {
    return $http({
            url: baseurl.getBaseUrl() + '/clientes/carga', 
            method: "POST",
            params: { filename:name, idEmpresa: localStorage.getItem('EMPRESA')}
          });
  }
 }
 
 
/**
 * Created by miguel on 10/08/2022.
 */
 angular.module('qualita.administracion')
 .factory('ProductoFactory', ProductoFactory);
 
 ProductoFactory.$inject = ['$resource', 'baseurl', '$log','$http'];
 
 function ProductoFactory($resource, baseurl, $log, $http) {
   var service = {
     all: all,
     get: get,
     create: create,
     save: save,
     remove: remove,
     allUsersWithoutAccess: allUsersWithoutAccess
   };
 
   var Producto = $resource( baseurl.getBaseUrl()  + "/productos/:id", {id: '@id'}, {
     update: {
       method: 'PUT'
     }
   });
 
   return service;
 
   function all(params, view) {
     if (params) {
       params.view = view!=undefined ? view : 'ProductoList';
     } else {
       params = [];
       params.view = view!=undefined ? view : 'ProductoList';
     }
     return Producto.query(params);
   }
 
   function allUsersWithoutAccess() {
     return Producto.usersWithoutAccess();
   }
 
   function get(id, view) {
     return Producto.get({id: id, view: view!=undefined ? view : 'ProductoForm'});
   }
 
   function create(attrs) {
     return new Producto(attrs);
   }
 
   function save(producto) {
     return (producto.id) ? producto.$update() : $http({
      url: baseurl.getBaseUrl() + "/productos/persist",
      method: "POST",
      data: JSON.stringify(producto)
    });
   }
 
   function remove(producto) {
     return producto.$remove();
   }

 }
 
 
angular.module('qualita.parametros', [])
  .config(
    ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
      function ($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {
        $urlMatcherFactoryProvider.strictMode(false);
        $urlRouterProvider.otherwise('/main');
        $stateProvider
          .state('app.parametros', {
            url: '/parametros',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Parametros'
            }
          })
          .state('app.parametros.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'ParametrosIndexCtrl',
            ncyBreadcrumb: {
              label: 'Parametros',
              description: 'Lista de parámetros'
            }
          })
          .state('app.parametros.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/parametros/views/form.html',
            controller: 'ParametrosFormController',
            ncyBreadcrumb: {
              parent: 'app.parametros',
              label: 'Editar Parametro',
              description: 'Edición del parámetro #'
            },
            resolve: {
              parametroPrepService: parametroPrepService
            }
          })
          .state('app.parametros.view', {
            url: '/:id',
            templateUrl: 'qualita-components/parametros/views/form.html',
            controller: 'ParametrosFormController',
            ncyBreadcrumb: {
              parent: 'app.parametros',
              label: 'Ver Parametro',
              description: 'Ver parámetro #'
            },
            resolve: {
              parametroPrepService: parametroPrepService
            }
          })

      }]);

function parametroPrepService(ParametrosFactory, $stateParams) {
  return ParametrosFactory.get($stateParams.id, "BaseForm").$promise;
}
parametroPrepService.$inject = ["ParametrosFactory", "$stateParams"];

/**
 * Created by codiumsa on 19/10/15.
 */
angular
  .module("qualita.parametros")
  .factory("ParametrosLangFactory", ParametrosLangFactory);

ParametrosLangFactory.$inject = [
  "$resource",
  "baseurl",
  "$translatePartialLoader",
  "$translate"
];

function ParametrosLangFactory(
  $resource,
  baseurl,
  $translatePartialLoader,
  $translate
) {
  var translationsNeeded = [
    "BACK",
    "CODE",
    "DESCRIPTION",
    "VALUE",
    "MODULO",
    "TIPO_DATO",
    "EDIT_PARAMETRO",
    "VIEW_PARAMETRO",
    "VALUE_TYPE",
    "PARAM"
  ];

  var service = {
    getTranslations: getTranslations
  };

  return service;

  function getTranslations() {
    $translatePartialLoader.addPart("parametros");
    return $translate.refresh().then(function() {
      return $translate(translationsNeeded);
    });
  }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.parametros
 * @description
 * # parametros
 * Factory in the qualita.
 */
angular.module('qualita.parametros')
  .factory('ParametrosFactory', ['TiposFactory', 'filterFactory', '$resource', 'baseurl',
    function (TiposFactory, filterFactory, $resource, baseurl) {
      var bandera = false;
      var Parametro = $resource(baseurl.getBaseUrl() + '/parametros/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });
      var ParametroCheck = $resource(baseurl.getBaseUrl() + '/parametroscheck/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          }
          return Parametro.query(params);
        },

        filterByCodigo: function (codigo, viewParam) {
          if (!viewParam) {
            viewParam = "Base";
          }
          var filter = {};
          filter.search = filterFactory.single({
            path: 'codigo',
            equals: codigo
          }).value();
          filter.view = viewParam
          return Parametro.query(filter);
        },

        getByCodigo: function (codigo) {
          var filter = {};
          filter.search = filterFactory.single({
            path: 'codigo',
            equals: codigo
          }).value();

          return Parametro.query(filter).$promise.then(function (response) {
            return response[0];
          });
        },

        filterByModulo: function (modulo) {
          var staticFilter = {};
          staticFilter.search = filterFactory.single({
            path: 'modulo.codigo',
            equals: modulo
          }).value();

          return Parametro.query(staticFilter);
        },

        get: function (id, view) {
          if (typeof (view) === 'undefined') view = "base";
          return Parametro.get({ id: id, view: view });
        },

        create: function (attrs) {
          return new Parametro(attrs);
        },

        createCheck: function (attrs) {
          return new ParametroCheck(attrs)
        },

        save: function (parametro) {
          return (parametro.id) ? parametro.$update() : parametro.$save();
        },

        remove: function (parametro) {
          return parametro.$remove();
        }
      };
    }]);

'use strict';


angular.module('qualita.parametros')
  .controller('ParametrosIndexCtrl', ["$scope", "ParametrosFactory", "filterFactory", "ReportTicketFactory", "$window", "ParametrosLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", function ($scope, ParametrosFactory, filterFactory, ReportTicketFactory, $window,
    ParametrosLangFactory, $filter, UtilFactory, CsvFactory, baseurl) {

    var defaultColumnOrder = ['id', 'codigo', 'descripcion', 'modulo.descripcion', 'tipoDato.descripcion', 'valor', 'valorTipo.descripcion'];

    ParametrosLangFactory.getTranslations().then(function (translations) {
      $scope.options = {
        'resource': 'parametros',
        'title': 'Parámetros',
        'factory': ParametrosFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
          { 'data': 'codigo', 'title': $filter('translate')('PARAM'), visible: false },
          { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION') },
          { 'data': 'modulo.descripcion', 'title': $filter('translate')('MODULO'), 'type': 'combo', 'filterUrl': 'parametros/tipos' },
          { 'data': 'tipoDato.descripcion', 'title': $filter('translate')('TIPO_DATO'), visible: false  },
          {
            'data': 'valor', 'title': $filter('translate')('VALUE'), 'class': 'dt-center', 'render': function (data, type, row) {
              if (row.valorTipo != undefined) {
                return row.valorTipo.descripcion;
              } else {
                return data;
              }
            }
          },
          {
            'data': 'valorTipo.descripcion', 'title': 'Valor Tipo', 'class': 'dt-center', visible: false, 'render': function (data, type, row) {
              if (data == undefined)
                return "";
              else
                return data;
            }
          }
        ],
        //'detailRows': []
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'hideAddMenu': true,
        'hideRemoveMenu': true,
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoParametros', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("parametros", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ]
      };

      var empresasFilter = {};
      empresasFilter.search = filterFactory.and([{
          path: "empresa.id",
          equals: localStorage.getItem('EMPRESA')
      }]).value();

      $scope.options.staticFilter = empresasFilter;

    });

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({ path: data, like: search })
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

angular.module('qualita.parametros')
  .controller('ParametrosFormController', ['$rootScope', '$scope', 'ParametrosFactory', 'formFactory', '$location',
    '$state', 'parametroPrepService', 'TiposTipoFactory', 'ModelTrimmer', 'ParametrosLangFactory', '$filter', 'notify',
    'filterFactory',
    function($rootScope, $scope, ParametrosFactory, formFactory, $location,$state, parametroPrepService,
      TiposTipoFactory, ModelTrimmer, ParametrosLangFactory, $filter, notify, filterFactory) {

      activate();

      $scope.valueIsLock = false;

      function activate() {
        ParametrosLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          if ($state.is("app.parametros.edit")) {
            activateEdit();
          } else if ($state.is("app.parametros.view")) {
            activateView();
          }
          $rootScope.isProcessing = false;
        });

      }

      function lockValor(){
        // Si existe algun parametro que no se pueda modificar
        if( ($scope.model.codigo == 'codigo')){
          $scope.valueIsLock = true;
        }
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_PARAMETRO');
        $scope.model = parametroPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Parametro";
        if($scope.model.tipoDato) {
          if($scope.model.tipoDato.codigo == 'personalizado') {
            var tipotipo = $scope.model.tipoTipoValores;
            if (tipotipo) {
              $scope.opcionesTipo = TiposTipoFactory.get(tipotipo.codigo);
            }
          } else if($scope.model.tipoDato.codigo == 'numerico') {
            $scope.model.valor = parseFloat($scope.model.valor);
          }
        }

        // Funcion para determinar si el campo valor debe bloquearse
        lockValor();
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_PARAMETRO');
        $scope.model = parametroPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Parametro";
        if($scope.model.tipoDato) {
          if($scope.model.tipoDato.codigo == 'personalizado') {
            var tipotipo = $scope.model.tipoTipoValores;
            if (tipotipo) {
              $scope.opcionesTipo = TiposTipoFactory.get(tipotipo.codigo);
            }
          } else if($scope.model.tipoDato.codigo == 'numerico') {
            $scope.model.valor = parseFloat($scope.model.valor);
          }
        }
        $scope.view = true;
      }

      $scope.submit = function(form) {
        if($scope.model.valorTipo != null){
          $scope.model.valor = $scope.model.valorTipo.descripcion;
        }

        var trimmedModel = ModelTrimmer.trimDetails($scope.model);
        if(trimmedModel.valor!=undefined) {
          trimmedModel.valor = trimmedModel.valor.toString();
        }

        formFactory.defaultNSFSubmit($scope.parametroForm, ParametrosFactory, trimmedModel).then(function(response) {
          $location.path('/parametros');
        }, function(e) {
          notify({ message: e, classes: 'alert-danger', position: 'right' });
        });
      };

      $scope.cancel = function() {
        $location.path('/parametros');
      };
    }
  ]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.comprobantes', ['ngResource', 'checklist-model'])
    .config(
        ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
            function($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {

            $stateProvider
                .state('app.comprobantes', {
                    url: '/comprobantes',
                    templateUrl: 'views/template_base/index.html',
                    abstract: true,
                    ncyBreadcrumb: {
                        label: 'Comprobantes Electrónicos'
                    }
                })
                .state('app.comprobantes.list', {
                    url: '',
                    templateUrl: 'qualita-components/comprobantes/views/comprobantes/consulta.html',
                    controller: 'ComprobantesCtrl',
                    ncyBreadcrumb: {
                        parent: 'app.comprobantes',
                        label: 'Comprobantes Electrónicos',
                        description: 'Listado de Documentos',
                        skip: true
                    }
                })
                .state('app.comprobantes.edit', {
                    url: '/:id/edit',
                    templateUrl: 'qualita-components/comprobantes/views/comprobantes/form.html',
                    controller: 'ComprobanteFormCtrl',
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.comprobantes',
                        label: 'Editar Comprobante',
                        description: 'Edición del comprobante #'
                    },
                    resolve: {
                        comprobantePrepService: comprobantePrepService
                       }
                })
                .state("app.comprobantes.carga", {
                    url: "/carga",
                    templateUrl: "qualita-components/comprobantes/views/comprobantes/carga.html",
                    controller: "ComprobanteCargaCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.comprobantes',
                        label: "Cargar Documentos",
                        description: "Cargar Documentos XML desde DPY Movil"
                    },
                    resolve: {
                        comprobantePrepService: function() { return; }
                    }
                })
                .state("app.comprobantes.cargacsv", {
                    url: "/cargacsv",
                    templateUrl: "qualita-components/comprobantes/views/comprobantes/cargacsv.html",
                    controller: "ComprobanteCargaCsvCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.comprobantes',
                        label: "Cargar Documentos CSV",
                        description: "Cargar Documentos CSV manualmente"
                    },
                    resolve: {
                        comprobantePrepService: function() { return; }
                    }
                })
            }
        ]
    )
    .animation('.repeated-item', function() {
        return {
            enter: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            leave: function(element, done) {
                element.css('opacity', 1);
                jQuery(element).animate({
                    opacity: 0
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            move: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            }

        }
    });

function comprobantePrepService(ComprobantesFactory, $stateParams) {
    return ComprobantesFactory.get($stateParams.id, "ComprobanteForm").$promise;
}
comprobantePrepService.$inject = ["ComprobantesFactory", "$stateParams"];
/**
 * Created by codiumsa on 30/9/15.
 */
angular.module("qualita.comprobantes").factory("ComprobantesLangFactory", [
  "$translatePartialLoader",
  "$translate",
  function(
    $translatePartialLoader,
    $translate
  ) {
    var translationsNeeded = [
      "ID",
      "TIPO",
      "TIPO_RECEPTOR",
      "TIPO_CONTRIBUYENTE RECEPTOR",
      "TIPO_OPERACION",
      "TIPO_ANTICIPO",
      "TIPO_DOCUMENTO RECEPTOR",
      "CODIGO_PAIS",
      "NOMBRE_FANTASIA RECEPTOR",
      "NUMERO_CASA RECEPTOR",
      "INFO_INTERES EMISOR",
      "INFO_INTERES FISCAL",
      "CODIGO_DEPARTAMENTO RECEPTOR",
      "DEPARTAMENTO_RECEPTOR",
      "CODIGO_DISTRITO RECPTOR",
      "DISTRITO",
      "CODIGO_CIUDAD RECEPTOR",
      "DESCRIPCION_CIUDAD RECEPTOR",
      "CELULAR_RECEPTOR",
      "CODIGO_CLIENTE",
      "TIPO_IMPUESTO AFECTADO",
      "TIMBRADO",
      "TIPO_DOC ELECTRONICO",
      "FECHA_INI TIMB",
      "NUMERO_COMPROBANTE",
      "FECHA_HORA",
      "TIPO_TRANSACCION",
      "MONEDA",
      "TIPO_CAMBIO",
      "RUC_RECEPTOR",
      "NOMBRE_RAZON SOCIAL",
      "CONDICION_VENTA",
      "ENTREGA_INICIAL",
      "CUOTAS",
      "GRAVADA10",
      "IVA10",
      "GRAVADA5",
      "IVA5",
      "EXENTO",
      "TOTAL_DESCUENTO",
      "TOTAL",
      "MOTIVO_EMISION",
      "NOMBRE_CSV PROCESADO",
      "NUMERO_LOTE",
      "C_D C",
      "Q_R",
      "DETALLE",
      "PAGOS",
      "DOCUMENTOS",
      "SUPERMERCADO",
      "DETALLE_CUOTAS",
      "SUCURSAL",
      "ESTADOS",
      "CORREO_ELECTRONICO",
      "TELEFONO",
      "DIRECCION",
      "PLAZOS",
      "GO_SET",
      "REENVIAR_MAIL",
      "CONFIRMAR_REENVIAR_MAIL",
      "RESPUESTA_LOTE",
      "CANCELAR_COMPROBANTE",
      "CONFIRMAR_CANCELAR_COMPROBANTE",
      "IMPRESION_RANGO",
      "CONFIRMAR_IMPRESION_RANGO",
      "NUMERO_COMPROBANTE_INICIAL",
      "NUMERO_COMPROBANTE_FINAL",
      "GO_REPROCESAR",
      "EDITAR_COMPROBANTE",
      "SAVE", "CANCEL", "CDC", "FECHA",
      "TIPO_DOCUMENTO_ASOCIADO",
      "GO_VERNOTIFICACION",
      "EVENTO", "DESTINATARIO"
    ];

    return {
      getTranslations: function() {
        $translatePartialLoader.addPart("documentos");
        return $translate.refresh().then(function() {
          return $translate(translationsNeeded);
        });
      }
    };
  }
]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.comprobantes')
.factory('ComprobantesFactory', ComprobantesFactory);

ComprobantesFactory.$inject = ['$resource', 'baseurl','$http'];

function ComprobantesFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    cancelar: cancelar,
    reenviarMail: reenviarMail,
    reenviarComprobanteSucursal: reenviarComprobanteSucursal,
    reprocesar: reprocesar,
    inutilizar: inutilizar,
    carga: carga,
    cargaCsv: cargaCsv,
    descargaXml: descargaXml,
    descargarKudeFecha: descargarKudeFecha
  };

  var Comprobantes = $resource( baseurl.getBaseUrl() + "/comprobantes/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  function all(params, view) {

    if (params) {
      params.view = view!=undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'BaseList';
    }
    return Comprobantes.query(params);
  }

  function get(id, view) {
    return Comprobantes.get({id: id, view: view!=undefined ? view : 'base'});
  }

  function create(attrs) {
    return new Comprobantes(attrs);
  }

  function save(comprobantes) {
    return (comprobantes.id) ? comprobantes.$update() : comprobantes.$save();
  }

  function remove(comprobantes) {
    return comprobantes.$remove();
  }

  function cancelar(cdc) {
    return $http({
      url: baseurl.getBaseUrl() + "/comprobantes/cancelar",
      method: "POST",
      data: JSON.stringify([{"cdc": cdc}])
    });
  }

  function reenviarMail(cdc) {
    return $http({
      url: baseurl.getBaseUrl() + "/comprobantes/reenviar",
      method: "POST",
      data: JSON.stringify([{"cdc": cdc}])
    });
  }

  function reenviarComprobanteSucursal(cdc) {
    return $http({
      url: baseurl.getBaseUrl() + "/comprobantes/reenviarcomprobantesucursal",
      method: "POST",
      data: JSON.stringify([{"cdc": cdc}])
    });
  }

  function reprocesar(id) {
    return $http({
      url: baseurl.getBaseUrl() + "/comprobantes/reprocesar/" + id,
      method: "POST"
    });
  }

  function inutilizar(numeroComprobante, numeroComprobanteFinal, tipoDocElectronico, timbrado) {
    if (numeroComprobanteFinal != null){
      return $http({
        url: baseurl.getBaseUrl() + "/comprobantes/inutilizarxrango",
        method: "POST",
        data: JSON.stringify([{"timbrado": timbrado, "numeroComprobante": numeroComprobante, "tipoDocElectronico": tipoDocElectronico},
        {"timbrado": timbrado, "numeroComprobante": numeroComprobanteFinal, "tipoDocElectronico": tipoDocElectronico}])
      });
    }else{
      return $http({
        url: baseurl.getBaseUrl() + "/comprobantes/inutilizar",
        method: "POST",
        data: JSON.stringify([{"timbrado": timbrado, "numeroComprobante": numeroComprobante, "tipoDocElectronico": tipoDocElectronico}])
      });
    }
    
  }

  function carga(name, id) {
    return $http({
            url: baseurl.getBaseUrl() + '/comprobantes/carga', 
            method: "POST",
            params: { filename:name, idEmpresa: localStorage.getItem('EMPRESA')}
          });
  }

  function cargaCsv(name, id) {
    return $http({
            url: baseurl.getBaseUrl() + '/comprobantes/cargacsv', 
            method: "POST",
            params: { filename:name, idEmpresa: localStorage.getItem('EMPRESA')}
          });
  }

  function descargaXml(id) {
      return $http({
            url: baseurl.getBaseUrl() + '/amazons3/descargacompletaxml?idEmpresa=' + id, 
            method: "GET"
          });
  }

  function descargarKudeFecha(timbrado, fechaInicio, fechaFin) {
    return $http({
          url: baseurl.getBaseUrl() + '/comprobantes/descargakudes?timbrado=' + timbrado + '&fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin, 
          method: "GET"
        });
  }

  return service;
}

'use strict';
  angular.module('qualita.comprobantes')
  .controller('ComprobantesCtrl', ["$scope", "$state", "notify", "$window", "ComprobantesLangFactory", "ComprobantesFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", "CONFIGURATION", "filterFactory", "$modal", "NotificacionMailFactory", "EmpresaFactory", "ParametrosFactory", "SucursalesFactory", "AuthorizationService", function ($scope, $state, notify,
    $window, ComprobantesLangFactory, ComprobantesFactory, CsvFactory, UtilFactory, baseurl, $filter,
    CONFIGURATION, filterFactory, $modal, NotificacionMailFactory, EmpresaFactory, ParametrosFactory, SucursalesFactory, AuthorizationService) {
    var defaultColumnOrder = ['id', 'numeroComprobante', 'fechaHora','tipo','tipoReceptor','tipoContribuyenteReceptor','tipoOperacion','tipoAnticipo',
      'tipoDocumentoReceptor','codigoPais','nombreFantasiaReceptor','numeroCasaReceptor','infoInteresEmisor','infoInteresFiscal',
      'codigoDepartamentoReceptor','departamentoReceptor','codigoDistritoRecptor','distrito','codigoCiudadReceptor',
      'descripcionCiudadReceptor','celularReceptor','codigoCliente','tipoIuestoAfectado','timbrado','tipoDocElectronico',
      'fechaIniTimb','tipoTransaccion','moneda','tipoCambio','rucReceptor','nombreRazonSocial',
      'condicionVenta','entregaInicial','cuotas','gravada10','iva10','gravada5','iva5','exento','totalDescuento','total',
      'motivoEmision','nombreCsvProcesado','numeroLote','CDC','QR','detalle','pagos','documentos','supermercado',
      'detalleCuotas','sucursal','estado','correoElectronico','telefono','direccion','respuestaProcesoLote','plazos', 'eventoAsociado', 'archivoEventoAsociado', 'CONFIGURATION'];

      var vm = this

      var imprimirSinAprobacion = localStorage.getItem("IMPRIMIR_SIN_APROBACION");
      var mostrarNotificacion = localStorage.getItem("MOSTRAR_NOTIFICACION_MAIL");
      var cargaManualCsv = localStorage.getItem("CARGA_MANUAL_CSV");
      var reprocesarComprobante = localStorage.getItem("REPROCESAR_COMPROBANTES");
      var edicionComprobanteRechazado = localStorage.getItem("EDICION_COMPROBANTE_RECHAZADO");
      var descargarXml = localStorage.getItem("DESCARGAR_XML_AMAZONS3");
      var descargarPdfRango = localStorage.getItem("DESCARGAR_PDF_RANGO");
      var tieneImpresionRango = localStorage.getItem("TEMPLATE_FACTURA_RANGO");
      var cargaDocumentosDpyMovil = localStorage.getItem("CARGA_DOCUMENTOS_DPY_MOVIL");
      var reenviarComprobanteSucursal = localStorage.getItem("REENVIAR_COMPROBANTE_SUCURSAL");

      $scope.filtros = {
      };

      $scope.estados = [
        {
          id: 1,
          descripcion: "Aprobado"
        },
        {
          id: 2,
          descripcion: "Rechazado"
        },
        {
          id: 3,
          descripcion: "Pendiente"
        },
        {
          id: 4,
          descripcion: "Enviado"
        },
        {
          id: 5, 
          descripcion: "Cancelado"
        },
        {
          id: 6,
          descripcion: "Inutilizado"
        },
        {
          id: 7,
          descripcion: "Error SIFEN"
        }
      ]

      $scope.tiposDocumentos = [{
        id: 1,
        valor: "Factura electrónica"
      },{
        id: 4,
        valor: "Autofactura"
      },{
        id: 5,
        valor: "Nota de crédito electrónica"
      },{
        id: 6, 
        valor: "Nota de débito electrónica"
      },{
        id: 7,
        valor: "Remisión electrónica"
      }];

      var monedaRender = function(data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda === 'USD') {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda === 'pesos') {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda === 'real') {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda === 'euro') {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                commafy(data
                  .toString()
                  .replace(".", ","))
        );
        } else {
            return '';
        }
    };

    var stringrender = function(data) {
      if (data) { 
        return data;
      } else {
          return '';
      }
    };

    function commafy( num ) {
      var str = num.toString().split(',');
      if (str[0].length >= 5) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');
      }
      if (str[1] && str[1].length >= 5) {
          str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      return str.join(',');
  }

    var tcRender = function(data, type, row) {
      if (data) {
          return (
              data
              .toString()
              .replace(".", ",")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      );
      } else {
          return '';
      }
    };

    var tComprobnateRender = function(data, type, row) {
      if (data === '1') {
          return 'Factura electrónica'
      } else if (data === '2') {
        return 'Factura electrónica de exportación'
      } else if (data === '3') {
        return 'Factura electrónica de importación'
      } else if (data === '4') {
        return 'Autofactura electrónica'
      } else if (data === '5') {
        return 'Nota de credito electronica'
      } else if (data === '6') {
        return 'Nota de debito electronica'
      } else if (data === '7') {
        return 'Nota de remision electronica'
      } else if (data === '8') {
        return 'Comprobante de retencion'
      } else {
          return '';
      }
    };

    ComprobantesLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      'resource': 'comprobantes',
      'title': 'Documentos Electrónicos',
      'factory': ComprobantesFactory,
      'view': 'ComprobanteList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        {'data': 'id', 'title': $filter('translate')('ID'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'numeroComprobante', 'title': $filter('translate')('NUMERO_COMPROBANTE'), 'class': 'dt-center', visible: true,'searchable': false},
        {'data': 'fechaHora', 'title': $filter('translate')('FECHA_HORA'), 'class': 'dt-center', visible: true,'searchable': false},
        {'data': 'tipo', 'title': $filter('translate')('TIPO'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoReceptor', 'title': $filter('translate')('TIPO_RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoContribuyenteReceptor', 'title': $filter('translate')('TIPO_CONTRIBUYENTE RECEPTOR'),'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoOperacion', 'title': $filter('translate')('TIPO_OPERACION'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoAnticipo', 'title': $filter('translate')('TIPO_ANTICIPO'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoDocumentoReceptor', 'title': $filter('translate')('TIPO_DOCUMENTO RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'codigoPais', 'title': $filter('translate')('CODIGO_PAIS'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'nombreFantasiaReceptor', 'title': $filter('translate')('NOMBRE_FANTASIA RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'nombreRazonSocial', 'title': $filter('translate')('NOMBRE_RAZON SOCIAL'), 'class': 'dt-center', visible: true,'searchable': false},
        {'data': 'numeroCasaReceptor', 'title': $filter('translate')('NUMERO_CASA RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'infoInteresEmisor', 'title': $filter('translate')('INFO_INTERES EMISOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'infoInteresFiscal', 'title': $filter('translate')('INFO_INTERES FISCAL'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'codigoDepartamentoReceptor', 'title': $filter('translate')('CODIGO_DEPARTAMENTO RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'departamentoReceptor', 'title': $filter('translate')('DEPARTAMENTO_RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'codigoDistritoRecptor', 'title': $filter('translate')('CODIGO_DISTRITO RECPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'distrito', 'title': $filter('translate')('DISTRITO'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'codigoCiudadReceptor', 'title': $filter('translate')('CODIGO_CIUDAD RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'descripcionCiudadReceptor', 'title': $filter('translate')('DESCRIPCION_CIUDAD RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'celularReceptor', 'title': $filter('translate')('CELULAR_RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'codigoCliente', 'title': $filter('translate')('CODIGO_CLIENTE'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoImpuestoAfectado', 'title': $filter('translate')('TIPO_IMPUESTO AFECTADO'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'timbrado', 'title': $filter('translate')('TIMBRADO'), 'class': 'dt-center', visible: true,'searchable': false},
        {'data': 'estado', 'title': $filter('translate')('ESTADOS'), 'class': 'dt-center', visible: true,'searchable': false},
        {'data': 'tipoDocElectronico', 'title': $filter('translate')('TIPO_DOC ELECTRONICO'), 'class': 'dt-center', visible: true, 'render': tComprobnateRender,'searchable': false},
        {'data': 'fechaIniTimb', 'title': $filter('translate')('FECHA_INI TIMB'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoTransaccion', 'title': $filter('translate')('TIPO_TRANSACCION'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'moneda', 'title': $filter('translate')('MONEDA'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'tipoCambio', 'title': $filter('translate')('TIPO_CAMBIO'), 'class': 'dt-center', visible: false, 'render': tcRender,'searchable': false},
        {'data': 'rucReceptor', 'title': $filter('translate')('RUC_RECEPTOR'), 'class': 'dt-center', visible: false,'searchable': false},
        {'data': 'entregaInicial', 'title': $filter('translate')('ENTREGA_INICIAL'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'cuotas', 'title': $filter('translate')('CUOTAS'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'gravada10', 'title': $filter('translate')('GRAVADA10'), 'class': 'dt-center', visible: false, 'render': monedaRender, 'searchable': false},
        {'data': 'iva10', 'title': $filter('translate')('IVA10'), 'class': 'dt-center', visible: false, 'render': monedaRender, 'searchable': false},
        {'data': 'gravada5', 'title': $filter('translate')('GRAVADA5'), 'class': 'dt-center', visible: false, 'render': monedaRender, 'searchable': false},
        {'data': 'iva5', 'title': $filter('translate')('IVA5'), 'class': 'dt-center', visible: false, 'render': monedaRender, 'searchable': false},
        {'data': 'exento', 'title': $filter('translate')('EXENTO'), 'class': 'dt-center', visible: false, 'render': monedaRender, 'searchable': false},
        {'data': 'totalDescuento', 'title': $filter('translate')('TOTAL_DESCUENTO'), 'class': 'dt-center', visible: true, 'render': monedaRender, 'searchable': false},
        {'data': 'total', 'title': $filter('translate')('TOTAL'), 'class': 'dt-center', visible: true, 'render': monedaRender, 'searchable': false},
        {'data': 'motivoEmision', 'title': $filter('translate')('MOTIVO_EMISION'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'nombreCsvProcesado', 'title': $filter('translate')('NOMBRE_CSV PROCESADO'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'numeroLote', 'title': $filter('translate')('NUMERO_LOTE'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'CDC', 'title': $filter('translate')('C_D C'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'QR', 'title': $filter('translate')('Q_R'), 'class': 'dt-center', visible: false, 'searchable': false},
       // {'data': 'detalle', 'title': $filter('translate')('DETALLE')},
       // {'data': 'pagos', 'title': $filter('translate')('PAGOS')},
       // {'data': 'documentos', 'title': $filter('translate')('DOCUMENTOS')},
       // {'data': 'supermercado', 'title': $filter('translate')('SUPERMERCADO')},
       // {'data': 'detalleCuotas', 'title': $filter('translate')('DETALLE_CUOTAS')},
       // {'data': 'sucursal', 'title': $filter('translate')('SUCURSAL')},
        {'data': 'correoElectronico', 'title': $filter('translate')('CORREO_ELECTRONICO'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'telefono', 'title': $filter('translate')('TELEFONO'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'direccion', 'title': $filter('translate')('DIRECCION'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'respuestaProcesoLote', 'title': $filter('translate')('RESPUESTA_LOTE'), 'class': 'dt-center', visible: true, 'searchable': false},
        {'data': 'plazos', 'title': $filter('translate')('PLAZOS'), 'class': 'dt-center', visible: false, 'searchable': false},
        {'data': 'eventoAsociado', 'title': $filter('translate')('EVENTO_ASOCIADO'), 'class': 'dt-center', visible: false, 'searchable': false, render: stringrender},
        {'data': 'archivoEventoAsociado', 'title': $filter('translate')('ARCHIVO_EVENTO_ASOCIADO'), 'class': 'dt-center', visible: false, 'searchable': false, render: stringrender}
      ],
      //'detailRows': []
      'hasOptions' : true,
      'defaultOrderColumn' : 2,
      'defaultOrderDir' : "desc",
      'failedDeleteError': 'No se pudo borrar el documento. Verifique si no hay Accesos al Sistema asociados.',
      'hideAddMenu' : false,
      'hideEditMenu' : true,
      'hideViewMenu' : true,
      'hideRemoveMenu':true,
      'extraMenuOptions':
        [
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createFilters($scope.options.getFilters(), $scope.options.staticFilter);
                CsvFactory.csv("comprobantes", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function(response) {
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          },
          {
            'title': 'Descargar XMLs generados',
            'icon': 'glyphicon glyphicon-cloud-download',
            'showCondition': function() { 
              if (descargarXml === "Sí") {
                return true;
              } else {
                  return false;
              }
             },
            'action': function() {
                $window.open(baseurl.getBaseUrl() + "/amazons3/descargacompletaxml?idEmpresa=" + localStorage.getItem("EMPRESA"));
            }
          },
          {
            'title': 'Inutilizar Comprobante',
            'icon': "glyphicon glyphicon-minus-sign",
            'showCondition': function() { return AuthorizationService.hasPermission("update_comprobantes"); },
            'action': function () {
              //Traer todos los comprobantes a ser mostrados en el modal ... ver que filtros que se deben aplicar
              var staticFilter = {};

              $scope.tituloModal = "Inutilizar comprobante";
              $scope.mensajeModal = "Esta seguro que desea inutilizar el comprobante?";


              SucursalesFactory.getTimbrado(localStorage.getItem("EMPRESA")).then(function(response){

                $scope.timbrado = response.data;

                var modalInstance = $modal.open({
                  templateUrl: "views/directives/inutilizar-modal.html",
                  scope: $scope
                });

                $scope.cancel = function () {
                  modalInstance.dismiss("cancel");
                };

                $scope.ok = function (numeroComprobante, numeroComprobanteFinal, tipoDocElectronico, timbrado) {
                  console.log("Inutiliza comprobante");
  
                  ComprobantesFactory.inutilizar(numeroComprobante, numeroComprobanteFinal, tipoDocElectronico.id, timbrado).then(function(response) {
                    if (response.status === 200) {
                      console.log(response.data);
                      if (response.data != null && response.data[0].estado != "Error"){
                        notify({
                          message: "Inutilizado con éxito",
                          classes: "alert-success",
                          position: "right"
                        });
                      }else{
                        notify({
                          message: response.data[0].descripcionResultado,
                          classes: "alert-danger",
                          position: "right"
                        });
                      }
                      
                      $state.go($state.current, {}, {reload: true});
                      modalInstance.close();
                    } else {
                      notify({
                        message: "Error al inutilizar comprobante",
                        classes: "alert-danger",
                        position: "right"
                      });
                      modalInstance.close();
                    }},
                    function(error) {
                      console.log("error " + error);
                      notify({
                        message: "Error al inutilizar comprobante",
                        classes: "alert-danger",
                        position: "right"
                      });
                      modalInstance.close();
                    });
                 }
              });

             }
          },
          {
            'title': 'Impresion por Rango',
            'icon': "glyphicon glyphicon-print",
            'showCondition': function() { 
              if (tieneImpresionRango === "Sí") {
                return true;
              } else {
                  return false;
              }
            },
            'action': function () {
              //Traer todos los comprobantes a ser mostrados en el modal ... ver que filtros que se deben aplicar
              var staticFilter = {};

              $scope.tituloModal = "Descargar Comprobantes por Rango";
              $scope.mensajeModal = "Esta seguro que desea descargar los comprobantes?";

              var modalInstance = $modal.open({
                templateUrl: "views/directives/impresion-rango-modal.html",
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss("cancel");
              };

              $scope.ok = function (codigoSucursal,puntoEmision,numeroDesde,numeroHasta) {
                if(numeroHasta.valueOf() < numeroDesde.valueOf()){
                  notify({
                    message: "El numero hasta debe ser mayor al numero desde",
                    classes: "alert-danger",
                    position: "center"
                  });
                  return
                }
                var queryParams = "nroComprobanteInicial="+codigoSucursal+"-"+puntoEmision+"-"+numeroDesde+"&nroComprobanteFinal="+codigoSucursal+"-"+puntoEmision+"-"+numeroHasta+"&idEmpresa="+localStorage.getItem('EMPRESA');
                $window.open(CONFIGURATION.serverFErango+queryParams,"_blank");
                $state.go($state.current, {}, {reload: true});
              }
            }
          },
          {
            'title': 'Descargar KuDEs por Fecha',
            'icon': "glyphicon glyphicon-save",
            'showCondition': function() { 
              if (descargarPdfRango === "Sí") {
                return true;
              } else {
                  return false;
              }
             },
            'action': function () {
              var staticFilter = {};

              $scope.tituloModal = "Descargar KuDEs por Fecha";

              SucursalesFactory.getTimbrado(localStorage.getItem("EMPRESA")).then(function(response){

                $scope.timbrado = response.data;

                var modalInstance = $modal.open({
                  templateUrl: "views/directives/descargar-kudes-fecha-modal.html",
                  scope: $scope
                });

                $scope.cancel = function () {
                  modalInstance.dismiss("cancel");
                };

                $scope.fechasValidas = function(fechaInicio, fechaFin) {
                  if (fechaInicio == undefined || fechaInicio == null || fechaFin == undefined || fechaFin == null) {
                      return false; 
                  }
                  
                  if (fechaFin < fechaInicio) {
                      return false;
                  }
          
                  var diferenciaEnMilisegundos = fechaFin.getTime() - fechaInicio.getTime();
          
                  var diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
          
                  if (diferenciaEnDias > 4) {
                      return false; // Retorna falso si la diferencia es mayor a 5 días
                  }
          
                  return true;
              };

                $scope.ok = function (fechaInicio, fechaFin) {
                  console.log("Descargar KuDEs por Fecha");

                  $('#preparandoDescargaModal').modal('show');

                  $window.open(baseurl.getBaseUrl() + '/comprobantes/descargakudes?timbrado=' + $scope.timbrado + '&fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin);
  
                  $('#preparandoDescargaModal').modal('hide');
                  $state.go($state.current, {}, {reload: true});
                  modalInstance.close();
                  
                 }
              });

             }
          },
          {
            title: "Carga Documentos DPY Movil",
            icon: 'glyphicon glyphicon-phone',
            showCondition: function () {
              if (cargaDocumentosDpyMovil === "Sí") {
                return true;
              } else {
                  return false;
              }
            },
            action: function () {
              $state.go("app.comprobantes.carga");
            }
          },
          {
            title: "Carga Documentos CSV",
            icon: 'glyphicon glyphicon-cloud-upload',
            showCondition: function () {
              if (cargaManualCsv === "Sí") {
                return true;
              } else {
                  return false;
              }
            },
            action: function () {
              $state.go("app.comprobantes.cargacsv");
            }
          }    
        ],
      extraRowOptions: [
        {
          templateToRender: "<button class='btn btn-primary' style='margin-right: 5px;' ng-click='goReprocesar(<%=dataCustom%>)' title='" + $filter('translate')('GO_REPROCESAR') + "' ng-class='{ hidden : !canReprocesar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
          customAttribute: ["id","estado"],
          conditionName: "canReprocesar",
          conditionDef: function(atributos) {
            var estado = atributos[1];
            if (reprocesarComprobante === "Sí" && estado === "Rechazado") {
                return true;
            } else {
                return false;
            }
          },
          functionName: "goReprocesar",
          functionDef: function(atributos) {
            $scope.tituloModal = $filter("translate")(
              "GO_REPROCESAR"
              );
              $scope.mensajeModal = $filter("translate")(
                "CONFIRMAR_REPROCESAR"
                );
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });


            $scope.cancel = function () {
              modalInstance.close();
            };

            $scope.ok = function () {
              var id = atributos[0];
              ComprobantesFactory.reprocesar(id).then(function(response) {
              if (response.status === 200) {
                notify({
                  message: "Reproceso con éxito",
                  classes: "alert-success",
                  position: "right"
                });
                $state.go($state.current, {}, {reload: true});
                modalInstance.close();
              } else {
                notify({
                  message: "Error al reprocesar comprobante",
                  classes: "alert-danger",
                  position: "right"
                });
                modalInstance.close();
              }},
              function(error) {
                console.log("error " + error);
                notify({
                  message: "Error al reprocesar comprobante",
                  classes: "alert-danger",
                  position: "right"
                });
                modalInstance.close();
              });
            }
          }
        },
        {
          templateToRender: "<button class='btn btn-primary' style='margin-right: 5px;' ng-click='goSET(<%=dataCustom%>)' title='" + $filter('translate')('GO_SET') + "' ng-class='{ hidden : !canSET(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-send'></span> </button>",
          customAttribute: ["estado", "QR"],
          conditionName: "canSET",
          conditionDef: function(atributos) {
            var estado = atributos[0];
            if (estado === "Aprobado" || estado === "Aprobado con observación" || estado === "Cancelado" ) {
                return true;
            } else {
                return false;
            }
          },
          functionName: "goSET",
          functionDef: function(atributos) {
            var QR = atributos[1];
            window.open(QR);
          }
        },{
          templateToRender: "<button title='" + $filter("translate")("DESCARGAR KuDE") +
                "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='descargarKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescarga(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
          functionName: "descargarKuDE",
          conditionName: "condicionDescarga",
          customAttribute: ["estado", "CDC"],
          conditionDef: function (atributos) {
            var estado = atributos[0];
            if (imprimirSinAprobacion === "Sí"){
              if (estado === "Aprobado" || estado ===  "Aprobado con observación" || estado ===  "Pendiente_homologacion" || estado === "Enviado" || estado === "Pendiente" || estado == "Error SIFEN" || estado === "Cancelado") {
                  return true;
              } else {
                  return false;
              }
            }else{
              if (estado === "Aprobado" || estado ===  "Aprobado con observación" || estado ===  "Pendiente_homologacion" || estado === "Cancelado") {
                return true;
              } else {
                  return false;
              }
            }

          },
          functionDef: function (atributos) {
            var cdc = atributos[1];
            $window.open(CONFIGURATION.serverFE+cdc,"_blank");
            $state.go($state.current, {}, {reload: false});
          }
        },{
          templateToRender: "<button title='" + $filter("translate")("EDITAR_COMPROBANTE") +
                "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='editarComprobante(<%=dataCustom%>)' ng-class='{ hidden : !condicionEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          functionName: "editarComprobante",
          conditionName: "condicionEditar",
          customAttribute: ["id", "estado", "tipoDocElectronico"],
          conditionDef: function (atributos) {
            var estado = atributos[1];
            
            if (edicionComprobanteRechazado == "Sí" && estado === "Rechazado") {
                return true;
            } else {
                return false;
            }

          },
          functionDef: function (atributos) {
            var id = atributos[0];
            $state.go("app.comprobantes.edit", { id: id });
          }
        },
        {
          templateToRender: "<button title='" + $filter("translate")("REENVIAR_MAIL") +
                  "' class='btn btn-default' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{ hidden : !condicionReenviar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-envelope'></span> </button>",
          functionName: "reenviar",
          conditionName: "condicionReenviar",
          customAttribute: ["estado", "CDC"],
          conditionDef: function (atributos) {
              var estado = atributos[0];
              if (estado === "Aprobado" ||  estado ===  "Aprobado con observación") {
                  return true;
              } else {
                  return false;
              }
          },
          functionDef: function (atributos) {
            $scope.tituloModal = $filter("translate")(
              "REENVIAR_MAIL"
              );
              $scope.mensajeModal = $filter("translate")(
                "CONFIRMAR_REENVIAR_MAIL"
                );
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });


            $scope.cancel = function () {
              modalInstance.close();
            };

            $scope.ok = function () {
              var cdc = atributos[1];
              ComprobantesFactory.reenviarMail(cdc).then(function(response) {
              if (response.status === 200) {
                notify({
                  message: "Envío de mail con éxito",
                  classes: "alert-success",
                  position: "right"
                });
                modalInstance.close();
              } else {
                notify({
                  message: "Error al enviar mail",
                  classes: "alert-danger",
                  position: "right"
                });
                modalInstance.close();
              }},
              function(error) {
                console.log("error " + error);
                notify({
                  message: "Error al enviar mail",
                  classes: "alert-danger",
                  position: "right"
                });
                modalInstance.close();
              });
            }

          }
      },
      {
        templateToRender: "<button title='" + $filter("translate")("REENVIAR_A_CENTRAL") +
                "' class='btn btn-primary' style='margin-right: 5px;' ng-click='reenviarACentral(<%=dataCustom%>)' ng-class='{ hidden : !condicionReenviarACentral(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
        functionName: "reenviarACentral",
        conditionName: "condicionReenviarACentral",
        customAttribute: ["estado", "CDC", "numeroComprobante"],
        conditionDef: function (atributos) {
            var estado = atributos[0];
            if (reenviarComprobanteSucursal == "Sí" && estado === "Enviado_Sucursal") {
                return true;
            } else {
                return false;
            }
        },
        functionDef: function (atributos) {
          $scope.tituloModal = $filter("translate")(
            "REENVIAR_COMPROBANTE_SUCURSAL"
            );
            $scope.mensajeModal = $filter("translate")(
              "CONFIRMAR_REENVIAR_COMPROBANTE_SUCURSAL"
              );
          var modalInstance = $modal.open({
            templateUrl: "views/directives/confirmation-modal.html",
            scope: $scope
          });


          $scope.cancel = function () {
            modalInstance.close();
          };

          $scope.ok = function () {
            var cdc = atributos[1];
            ComprobantesFactory.reenviarComprobanteSucursal(cdc).then(function(response) {
            if (response.status === 200) {
              notify({
                message: "Cambio de estado con éxito. Comprobante se reenviará en unos instantes...",
                classes: "alert-success",
                position: "right"
              });
              modalInstance.close();
              $state.go($state.current, {}, {reload: true});
            } else {
              notify({
                message: "Error al reenviar comprobante",
                classes: "alert-danger",
                position: "right"
              });
              modalInstance.close();
            }},
            function(error) {
              console.log("error " + error);
              notify({
                message: "Error al reenviar comprobante",
                classes: "alert-danger",
                position: "right"
              });
              modalInstance.close();
            });
          }

        }
      },
      {
        templateToRender: "<button title='" + $filter("translate")("CANCELAR_COMPROBANTE") +
                "' class='btn btn-danger' style='margin-right: 5px;' ng-click='cancelar(<%=dataCustom%>)' ng-class='{ hidden : !condicionCancelar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
        functionName: "cancelar",
        conditionName: "condicionCancelar",
        customAttribute: ["estado", "CDC"],
        conditionDef: function (atributos) {
            var estado = atributos[0];
            if (AuthorizationService.hasPermission("update_comprobantes") && (estado === "Aprobado" ||  estado ===  "Aprobado con observación")) {
                return true;
            } else {
                return false;
            }
        },
        functionDef: function (atributos) {

          $scope.tituloModal = $filter("translate")(
            "CANCELAR_COMPROBANTE"
            );
            $scope.mensajeModal = $filter("translate")(
              "CONFIRMAR_CANCELAR_COMPROBANTE"
              );
          var modalInstance = $modal.open({
            templateUrl: "views/directives/confirmation-modal.html",
            scope: $scope
          });


          $scope.cancel = function () {
            modalInstance.dismiss("cancel");
          };

          $scope.ok = function () {

            var cdc = atributos[1];
            ComprobantesFactory.cancelar(cdc).then(function(response) {
            if (response.data[0].estadoResultado === 1) {
              notify({
                message: response.data[0].descripcionResultado,
                classes: "alert-success",
                position: "right"
              });
            } else {
              notify({
                message: response.data[0].descripcionResultado,
                classes: "alert-danger",
                position: "right"
              });
              $state.go($state.current, {}, {reload: true});
            }},
            function(error) {
              console.log("error " + error);
              notify({
                message: error,
                classes: "alert-danger",
                position: "right"
              });
            });
          }
        }
    },
    {
      templateToRender: "<button class='btn btn-primary' style='margin-right: 5px;' ng-click='goVerNotificacion(<%=dataCustom%>)' title='" + $filter('translate')('GO_VERNOTIFICACION') + "' ng-class='{ hidden : !canVerNotificacion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-comment'></span> </button>",
      customAttribute: ["id","estado"],
      conditionName: "canVerNotificacion",
      view: "datatables",
      conditionDef: function(atributos) {
        var estado = atributos[1];
        if (estado === "Aprobado" && mostrarNotificacion === "Sí") {
            return true;
        } else {
            return false;
        }
      },
      functionName: "goVerNotificacion",
      functionDef: function(atributos) {
        var id = atributos[0];
        var defaultColumnOrder = [
            "id",
            "evento",
            "fecha",
            "destinatario",
            "motivoError"
        ];

        $scope.optionsDTModal = {
            resource: "snsnotificacion",
            title: $filter("translate")("NOTIFICACION_MAIL"),
            factory: NotificacionMailFactory,
            view: "BaseList",
            defaultColumnOrder: defaultColumnOrder,
            columns: [{
                    data: "id",
                    title: $filter("translate")("ID"),
                    type: "number-range",
                    visible: false
                },
                {
                    data: "evento",
                    title: $filter("translate")("EVENTO")
                },
                {
                  data: "fecha",
                  title: $filter("translate")("FECHA")
                },
                {
                  data: "destinatario",
                  title: $filter("translate")("DESTINATARIO")
                },
                {
                  data: "motivoError",
                  title: "Información adicional",
                  render: function(data, type, full, meta) {
                    if (type === 'display') {
                        // Mostrar solo los primeros 20 caracteres en la grilla
                        if (data != null){
                          var truncatedData = data.length > 45 ? data.substr(0, 45) + '...' : data;
                        
                          // Agregar tooltip con la información completa
                          return '<span title="' + data + '">' + truncatedData + '</span>';
                        }

                        return data;
                    }
                    
                    // Devolver la información completa para otros tipos de renderizado (ordenamiento, filtrado, etc.)
                    return data;
                  }
                }
            ],
            hasOptions: false,
            hideAddMenu: true,
            hideEditMenu: true,
            hideViewMenu: true,
            hideRemoveMenu: true,
            defaultOrderColumn: 0,
            defaultOrderDir: "desc",
            staticFilter: {
                search: filterFactory
                    .and([{
                        path: "facturaCabecera.id",
                        equals: id
                    }])
                    .value()
            }
        };

        var modalInstance = $modal.open({
            template: "<div tdn-datatable=\"\" options=\"optionsDTModal\"></div>",
            scope: $scope,
            size: "lg"
        });

        $scope.cancel = function () {
          modalInstance.dismiss("cancel");
        };
      }
    }],
  };

  var empresasFilter = {};
  empresasFilter.search = filterFactory.and([{
      path: "empresa.id",
      equals: localStorage.getItem('EMPRESA')
  }]).value();

  $scope.options.staticFilter = empresasFilter;

  $scope.consultar = function(){
    var listaFiltros = [];
    listaFiltros.push({
        path: "empresa.id",
        equals: localStorage.getItem('EMPRESA')
    });
    if ($scope.filtros.numeroComprobante != null){
      listaFiltros.push({
          path: "numeroComprobante",
          like: "%" + $scope.filtros.numeroComprobante + "%"
      })
    }
    if ($scope.filtros.estado != null){
      listaFiltros.push({
          path: "estado",
          equals: $scope.filtros.estado.descripcion
      })
    }
    if ($scope.filtros.tipoComprobante != null){
      listaFiltros.push({
          path: "tipoDocElectronico",
          equals: $scope.filtros.tipoComprobante.id + ""
      })
    }
    if ($scope.filtros.fechaDesde != null){
      var parse1 = moment($scope.filtros.fechaDesde).format('DD/MM/YYYY');
      listaFiltros.push({
          path: "fechaHora",
          equalOrAfter: parse1
      })
    }
    if ($scope.filtros.fechaHasta != null){
      $scope.filtros.fechaHasta.setDate($scope.filtros.fechaHasta.getDate() + 1);
      var parse2 = moment($scope.filtros.fechaHasta).format('DD/MM/YYYY');
      listaFiltros.push({
          path: "fechaHora",
          before: parse2
      })
      $scope.filtros.fechaHasta.setDate($scope.filtros.fechaHasta.getDate() - 1);
    }
    if ($scope.filtros.respuestaProcesoLote != null){
      listaFiltros.push({
          path: "respuestaProcesoLote",
          like: "%"+$scope.filtros.respuestaProcesoLote+"%"
      })
    }
    if ($scope.filtros.razonSocial != null){
      listaFiltros.push({
          path: "nombreRazonSocial",
          like: "%" + $scope.filtros.razonSocial + "%"
      })
    }
    if ($scope.filtros.cdc != null){
      listaFiltros.push({
          path: "CDC",
          equals: $scope.filtros.cdc
      })
    }
    var staticFilter = {
      search: filterFactory.and(listaFiltros).value()
    }
    $scope.options.staticFilter = staticFilter;
  }
  $scope.cleanFilters = function(){
    $scope.filtros = {};
  }

  $scope.validarNumeroComprobanteHasta= function(numeroDesde, numeroHasta){
    return numeroHasta > numeroDesde;
  }
});
}]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.comprobantes')
  .controller('ComprobanteFormCtrl', ComprobanteFormCtrl);

ComprobanteFormCtrl.$inject = ['$rootScope', '$scope', 'ComprobantesFactory', '$location', '$state',
'ComprobantesLangFactory', 'notify', '$filter', 'comprobantePrepService'];

function ComprobanteFormCtrl($rootScope, $scope, ComprobantesFactory, $location, $state,
  ComprobantesLangFactory, notify, $filter, comprobantePrepService) {
  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.removeItemFromArray = removeItemFromArray;
  vm.agregarDetalleProducto = agregarDetalleProducto;
  vm.calcularTotalDetalles = calcularTotalDetalles;

  activate();

  vm.tiposDocumentoAsociado = [
    {"id": 1, "descripcion": "Documento electrónico"},
    {"id": 2, "descripcion": "Documento impreso"}];

  vm.tipoDocumentoChanged = function(){
     if (vm.comprobante.documentoAsociado.comprobanteAsociado.id == 1){
        vm.comprobante.documentoAsociado.numeroComprobante = null;
        vm.comprobante.documentoAsociado.timbrado = null;
        vm.comprobante.documentoAsociado.fechaDocumento = null;
     }else{
        vm.comprobante.documentoAsociado.cdc = null;
     }
  }

  vm.totalGeneralProductos = 0;
  vm.totalGravada10 = 0;
  vm.totalGravada5 = 0;
  vm.totalIva10 = 0;
  vm.totalIva5 = 0;
  vm.totalExento = 0;

  function activate() {
    ComprobantesLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      if($state.is("app.comprobantes.new")) {
        activateNew();
      } else if($state.is("app.comprobantes.edit")) {
        activateEdit();
      } else if($state.is("app.comprobantes.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVO_COMPROBANTE');
    vm.comprobante = {};
    vm.comprobante.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === "PRY";
    });
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDITAR_COMPROBANTE');
    vm.comprobante = comprobantePrepService;
    vm.comprobante.documentoAsociado = {
        comprobanteAsociado: vm.tiposDocumentoAsociado[0]
    }
    vm.comprobante.correoElectronico = vm.comprobante.destinatarioOriginal;
    if (vm.comprobante.documentos.length >0){
        var fechaFormateada = null;
        if (vm.comprobante.documentos[0].fechaDocumento!=null){
            var partesFecha = vm.comprobante.documentos[0].fechaDocumento.split("/");
            var dia = parseInt(partesFecha[0])+1;
            dia = (dia<10)? "0"+dia:""+dia;
            fechaFormateada = partesFecha[2] + "-" + partesFecha[1] + "-" + dia + "T00:00:00.000Z";
        }

        vm.comprobante.documentoAsociado = {
            comprobanteAsociado: (vm.comprobante.documentos[0].comprobanteAsociado == 1)? vm.tiposDocumentoAsociado[0]:vm.tiposDocumentoAsociado[1],
            cdc: vm.comprobante.documentos[0].cdc,
            numeroComprobante: vm.comprobante.documentos[0].numeroComprobante,
            timbrado: (vm.comprobante.documentos[0].timbrado!=null)?parseInt(vm.comprobante.documentos[0].timbrado):null,
            fechaDocumento: (fechaFormateada!=null)?new Date(fechaFormateada): null
        }  
        
    }
    calcularTotalDetalles(vm.comprobante.detalle);
    $scope.entidadId=vm.comprobante.id;
    $scope.entidad="Comprobante";
  }

  function activateView() {
    vm.title = $filter('translate')('VER_COMPROBANTE');
    vm.comprobante = comprobantePrepService;
    if (vm.comprobante.tipoContribuyente == "Física"){
      vm.comprobante.tipoContribuyente = vm.tiposContribuyente[0];
    }else if (vm.comprobante.tipoContribuyente == "Jurídica"){
        vm.comprobante.tipoContribuyente = vm.tiposContribuyente[1];
    }
    if (vm.comprobante.codigoPais != null){
      vm.comprobante.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === vm.comprobante.codigoPais;
      });
    }else{
      vm.comprobante.codigoPais = _.find(vm.paises, function(pais){
        return pais.codigo === "PRY";
      });
    }
    calcularTotalDetalles(vm.comprobante.detalle);
    $scope.entidadId=vm.comprobante.id;
    $scope.entidad="Comprobante";
    vm.view = true;
  }

  function removeItemFromArray(elemento, arreglo, index) {
    _.remove(arreglo, function (item) {
      return item === elemento;
    });
    calcularTotalDetalles(arreglo);
  }

  function agregarDetalleProducto() {
    vm.comprobante.detalle.push({
        codigo: null,
        descripcion: null,
        cantidad: 0,
        precioUnitario: 0,
        total: 0,
        gravado10: 0,
        gravado5: 0,
        iva10: 0,
        iva5: 0,
        exento: 0
    });

    $timeout(function () {
        $scope.$broadcast("newItemAdded");
    }, 20);
}

  function calcularTotalDetalles(){
    vm.totalGeneralProductos = 0;
    vm.totalGravada10 = 0;
    vm.totalGravada5 = 0;
    vm.totalIva10 = 0;
    vm.totalIva5 = 0;
    vm.totalExento = 0;
    _.forEach(vm.comprobante.detalle, function(det){
        vm.totalGeneralProductos += det.total;
        det.importeItems = det.total; // Falta este importe...
        det.unidadMedida = "77";
        vm.totalGravada10 += det.gravado10;
        vm.totalGravada5 += det.gravado5;
        vm.totalIva10 += det.iva10;
        vm.totalIva5 += det.iva5;
        vm.totalExento += det.exento;
    });
    vm.totalGeneralProductos = Math.ceil(vm.totalGeneralProductos*100)/100;
    vm.totalGeneralProductos = vm.totalGeneralProductos.toFixed(2);
    vm.totalGravada10 = Math.ceil(vm.totalGravada10*100)/100;
    vm.totalGravada10 = vm.totalGravada10.toFixed(2);
    vm.totalGravada5 = Math.ceil(vm.totalGravada5*100)/100;
    vm.totalGravada5 = vm.totalGravada5.toFixed(2);
    vm.totalIva10 = Math.ceil(vm.totalIva10*100)/100;
    vm.totalIva10 = vm.totalIva10.toFixed(2);
    vm.totalIva5 = Math.ceil(vm.totalIva5*100)/100;
    vm.totalIva5 = vm.totalIva5.toFixed(2);
    vm.totalExento = Math.ceil(vm.totalExento*100)/100;
    vm.totalExento = vm.totalExento.toFixed(2);
  }

  function submit() {
    vm.submited = true;
    if($scope.ComprobanteForm.$valid) {
      $rootScope.isProcessing = true;
      
      //validamos numerodocumento.
      var valido = true;
      if (vm.comprobante.documentoAsociado.numeroComprobante != null ){
        valido = false;
        var partes = vm.comprobante.documentoAsociado.numeroComprobante.split("-");
        if (partes.length === 3){
            if (partes[0].length === 3 && !isNaN(partes[0])){
                if (partes[1].length === 3 && !isNaN(partes[1])){
                    if (partes[2].length === 7 && !isNaN(partes[2])){
                        valido = true;
                    }

                }
            }
        }
      }

      if (valido){

        var dia, mes, anio;
        if (vm.comprobante.documentoAsociado.fechaDocumento != null){
            dia = vm.comprobante.documentoAsociado.fechaDocumento.getDate();
            dia = (dia<10)?"0"+dia: ""+dia;
            mes = vm.comprobante.documentoAsociado.fechaDocumento.getMonth()+1;
            mes = (mes<10)?"0"+mes: ""+mes;
            anio = vm.comprobante.documentoAsociado.fechaDocumento.getYear()+1900;
            anio = "" + anio;
        }
        
        vm.comprobante.documentos = [
            {
                comprobanteAsociado: vm.comprobante.documentoAsociado.comprobanteAsociado.id,
                cdc: vm.comprobante.documentoAsociado.cdc,
                numeroComprobante: vm.comprobante.documentoAsociado.numeroComprobante,
                timbrado: vm.comprobante.documentoAsociado.timbrado,
                fechaDocumento: (dia!=null)?dia+"/"+mes+"/"+anio:null
            }
        ]
        vm.comprobante.documentoAsociado = undefined;
      }else{
        notify({
            message: "Formato de número de documento no válido",
            classes: 'alert-danger',
            position: 'right'
          });
        $rootScope.isProcessing = false;
        return;
      }

      vm.comprobante.total = vm.totalGeneralProductos;
      vm.comprobante.gravada10 = vm.totalGravada10;
      vm.comprobante.gravada5 = vm.totalGravada5;
      vm.comprobante.iva10 = vm.totalIva10;
      vm.comprobante.iva5 = vm.totalIva5;
      vm.comprobante.exento = vm.totalExento;

     submitComprobante().then(function () {
        notify({
            message: "Reproceso con éxito ",
            classes: 'alert-success',
            position: 'right'
          });
        $location.path('/comprobantes');
      }, function(error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    }

    function submitComprobante() {
      return ComprobantesFactory.save(vm.comprobante);
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $location.path("/comprobantes");
  }

  $scope.resource = 'Comprobante'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

/**
 * Created by codiumsa on 19/10/15.
 */
 angular.module('qualita.comprobantes')
 .factory('NotificacionMailFactory', NotificacionMailFactory);
 
 NotificacionMailFactory.$inject = ['$resource', 'baseurl'];
 
 function NotificacionMailFactory($resource, baseurl) {
   var service = {
     all: all,
     get: get
   };
 
   var notificaciones = $resource( baseurl.getBaseUrl() + "/snsnotificacion/:id", {id: '@id'}, {
     update: {
       method: 'PUT'
     }
   });
 
   function all(params, view) {
 
     if (params) {
       params.view = view!=undefined ? view : 'BaseList';
     } else {
       params = [];
       params.view = view!=undefined ? view : 'BaseList';
     }
     return notificaciones.query(params);
   }
 
   function get(id, view) {
     return notificaciones.get({id: id, view: view!=undefined ? view : 'base'});
   }

   return service;
 }
 
'use strict';

angular.module('qualita.comprobantes')
  .controller('ComprobanteCargaCtrl', ["$http", "$rootScope", "$location", "$scope", "formFactory", "$window", "baseurl", "usSpinnerService", "notify", "$state", "ComprobantesFactory", function ($http ,$rootScope, $location,$scope, formFactory, $window, baseurl, usSpinnerService,notify,$state, ComprobantesFactory) {

    $scope.title = 'Cargar Documentos';

    $rootScope.isProcessing = false;

    $scope.schema = {
      type: 'object',
      title: 'Comprobante',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

     var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/comprobanteUpload'
      },
      uploader: {}
    };

    $scope.form = [
      fileFormProperty
    ];

    $scope.options = formFactory.defaultOptions();

    $scope.resource = 'comprobante';

    $scope.model = {};

    $scope.position = 0;

    $scope.formSent = false;

    $scope.submitButton = 'Actualizar datos';

    $scope.startSpin = function(){
        usSpinnerService.spin('spinner-1');
    }

    $scope.stopSpin = function(){
        usSpinnerService.stop('spinner-1');
    }
    var vm = this;
    $scope.submit = function(form) {
      var flow = fileFormProperty.uploader.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        $scope.model.file = flowFile.name;
        $scope.$broadcast('schemaFormValidate');
        var model = ComprobantesFactory.create($scope.model);
        $scope.formSent = true;
        $scope.startSpin();

        $rootScope.isProcessing = true;
        ComprobantesFactory.carga($scope.model.file).then(function(data){
          console.log("success");
          if(data.file === null || data.file === undefined) {
            $scope.stopSpin();
            $scope.formSent = false;
            notify({
              message: "Archivo procesado con éxito",
              classes: 'alert-success',
              position: 'right'
            });
            $state.go("app.comprobantes.list");
          } else {
              $scope.stopSpin();
              $scope.formSent = false;
              $state.go("app.comprobantes.list");
          }
        }, function(error){
          $rootScope.isProcessing = false;
          $scope.stopSpin();
		      $scope.formSent = false;
          notify({
            message: error.data[0].message,
            classes: 'alert-danger',
            position: 'right'
          });
        });
    };

    $scope.cancel = function(form) {
    }
  }]);

'use strict';

angular.module('qualita.comprobantes')
  .controller('ComprobanteCargaCsvCtrl', ["$http", "$rootScope", "$location", "$scope", "formFactory", "$window", "baseurl", "usSpinnerService", "notify", "$state", "ComprobantesFactory", function ($http ,$rootScope, $location,$scope, formFactory, $window, baseurl, usSpinnerService,notify,$state, ComprobantesFactory) {

    $scope.title = 'Cargar Documentos CSV';

    $rootScope.isProcessing = false;

    $scope.schema = {
      type: 'object',
      title: 'Comprobante',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

     var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/comprobanteUploadCsv'
      },
      uploader: {}
    };

    $scope.form = [
      fileFormProperty
    ];

    $scope.options = formFactory.defaultOptions();

    $scope.resource = 'comprobante';

    $scope.model = {};

    $scope.position = 0;

    $scope.formSent = false;

    $scope.submitButton = 'Actualizar datos';

    $scope.startSpin = function(){
        usSpinnerService.spin('spinner-1');
    }

    $scope.stopSpin = function(){
        usSpinnerService.stop('spinner-1');
    }
    var vm = this;
    $scope.submit = function(form) {
      var flow = fileFormProperty.uploader.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        $scope.model.file = flowFile.name;
        $scope.$broadcast('schemaFormValidate');
        var model = ComprobantesFactory.create($scope.model);
        $scope.formSent = true;
        $scope.startSpin();

        $rootScope.isProcessing = true;
        ComprobantesFactory.cargaCsv($scope.model.file).then(function(data){
          console.log("success");
          if(data.file === null || data.file === undefined) {
            $scope.stopSpin();
            $scope.formSent = false;
            if (data.data[0].estado != "Error"){
              notify({
                message: "Archivo procesado con éxito",
                classes: 'alert-success',
                position: 'right'
              });
            }else{
              notify({
                message: data.data[0].descripcionResultado,
                classes: 'alert-danger',
                position: 'right'
              });
            }
            $state.go("app.comprobantes.list");
          } else {
              $scope.stopSpin();
              $scope.formSent = false;
              $state.go("app.comprobantes.list");
          }
        }, function(error){
          $rootScope.isProcessing = false;
          $scope.stopSpin();
		      $scope.formSent = false;
          notify({
            message: error.data[0].message,
            classes: 'alert-danger',
            position: 'right'
          });
        });
    };

    $scope.cancel = function(form) {
    }
  }]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.comprobanteshistorico', ['ngResource', 'checklist-model'])
    .config(
        ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
            function($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {

            $stateProvider
                .state('app.comprobanteshistorico', {
                    url: '/comprobanteshistorico',
                    templateUrl: '../../views/template_base/index.html',
                    abstract: true,
                    ncyBreadcrumb: {
                        label: 'Comprobantes Electrónicos (Histórico)'
                    }
                })
                .state('app.comprobanteshistorico.list', {
                    url: '',
                    templateUrl: 'qualita-components/comprobantesHistorico/views/comprobantes/consulta.html',
                    controller: 'comprobanteshistoricoCtrl',
                    ncyBreadcrumb: {
                        parent: 'app.comprobanteshistorico',
                        label: 'Historico de comprobantes',
                        description: 'Listado de Documentos',
                        skip: true
                    }
                })
            }
        ]
    )
    .animation('.repeated-item', function() {
        return {
            enter: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            leave: function(element, done) {
                element.css('opacity', 1);
                jQuery(element).animate({
                    opacity: 0
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            move: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            }

        }
    });
/**
 * Created by codiumsa on 30/9/15.
 */
angular.module("qualita.comprobanteshistorico").factory("ComprobantesHistoricoLangFactory", [
  "$translatePartialLoader",
  "$translate",
  function(
    $translatePartialLoader,
    $translate
  ) {
    var translationsNeeded = [
      "ID",
      "TIPO",
      "TIPO_RECEPTOR",
      "TIPO_CONTRIBUYENTE RECEPTOR",
      "TIPO_OPERACION",
      "TIPO_ANTICIPO",
      "TIPO_DOCUMENTO RECEPTOR",
      "CODIGO_PAIS",
      "NOMBRE_FANTASIA RECEPTOR",
      "NUMERO_CASA RECEPTOR",
      "INFO_INTERES EMISOR",
      "INFO_INTERES FISCAL",
      "CODIGO_DEPARTAMENTO RECEPTOR",
      "DEPARTAMENTO_RECEPTOR",
      "CODIGO_DISTRITO RECPTOR",
      "DISTRITO",
      "CODIGO_CIUDAD RECEPTOR",
      "DESCRIPCION_CIUDAD RECEPTOR",
      "CELULAR_RECEPTOR",
      "CODIGO_CLIENTE",
      "TIPO_IMPUESTO AFECTADO",
      "TIMBRADO",
      "TIPO_DOC ELECTRONICO",
      "FECHA_INI TIMB",
      "NUMERO_COMPROBANTE",
      "FECHA_HORA",
      "TIPO_TRANSACCION",
      "MONEDA",
      "TIPO_CAMBIO",
      "RUC_RECEPTOR",
      "NOMBRE_RAZON SOCIAL",
      "CONDICION_VENTA",
      "ENTREGA_INICIAL",
      "CUOTAS",
      "GRAVADA10",
      "IVA10",
      "GRAVADA5",
      "IVA5",
      "EXENTO",
      "TOTAL_DESCUENTO",
      "TOTAL",
      "MOTIVO_EMISION",
      "NOMBRE_CSV PROCESADO",
      "NUMERO_LOTE",
      "C_D C",
      "Q_R",
      "DETALLE",
      "PAGOS",
      "DOCUMENTOS",
      "SUPERMERCADO",
      "DETALLE_CUOTAS",
      "SUCURSAL",
      "ESTADOS",
      "CORREO_ELECTRONICO",
      "TELEFONO",
      "DIRECCION",
      "PLAZOS",
      "GO_SET",
      "REENVIAR_MAIL",
      "CONFIRMAR_REENVIAR_MAIL",
      "RESPUESTA_LOTE",
      "CANCELAR_COMPROBANTE",
      "CONFIRMAR_CANCELAR_COMPROBANTE"
    ];

    return {
      getTranslations: function() {
        $translatePartialLoader.addPart("documentos");
        return $translate.refresh().then(function() {
          return $translate(translationsNeeded);
        });
      }
    };
  }
]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.comprobanteshistorico')
.factory('ComprobantesFactoryHistorico', ComprobantesFactoryHistorico);

ComprobantesFactoryHistorico.$inject = ['$resource', 'baseurl','$http'];

function ComprobantesFactoryHistorico($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    cancelar: cancelar,
    reenviarMail: reenviarMail
  };

  var Comprobantes = $resource( baseurl.getBaseUrl() + "/comprobanteshistorico/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  function all(params, view) {

    if (params) {
      params.view = view!=undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'BaseList';
    }
    return Comprobantes.query(params);
  }

  function get(id, view) {
    return Comprobantes.get({id: id, view: view!=undefined ? view : 'base'});
  }

  function create(attrs) {
    return new Comprobantes(attrs);
  }

  function save(comprobantes) {
    return (comprobantes.id) ? comprobantes.$update() : comprobantes.$save();
  }

  function remove(comprobantes) {
    return comprobantes.$remove();
  }

  function cancelar(cdc) {
    return $http({
      url: baseurl.getBaseUrl() + "/comprobanteshistorico/cancelar",
      method: "POST",
      data: JSON.stringify([{"cdc": cdc}])
    });
  }

  function reenviarMail(cdc) {
    return $http({
      url: baseurl.getBaseUrl() + "/comprobanteshistorico/reenviar",
      method: "POST",
      data: JSON.stringify([{"cdc": cdc}])
    });
  }

  return service;
}

/**
 * Created by codiumsa on 19/10/15.
 */
 angular.module('qualita.comprobantes')
 .factory('NotificacionHistoricoMailFactory', NotificacionHistoricoMailFactory);
 
 NotificacionHistoricoMailFactory.$inject = ['$resource', 'baseurl'];
 
 function NotificacionHistoricoMailFactory($resource, baseurl) {
   var service = {
     all: all,
     get: get
   };
 
   var notificaciones = $resource( baseurl.getBaseUrl() + "/snsnotificacionhistorico/:id", {id: '@id'}, {
     update: {
       method: 'PUT'
     }
   });
 
   function all(params, view) {
 
     if (params) {
       params.view = view!=undefined ? view : 'BaseList';
     } else {
       params = [];
       params.view = view!=undefined ? view : 'BaseList';
     }
     return notificaciones.query(params);
   }
 
   function get(id, view) {
     return notificaciones.get({id: id, view: view!=undefined ? view : 'base'});
   }

   return service;
 }
 
'use strict';
  angular.module('qualita.comprobantes')
  .controller('comprobanteshistoricoCtrl', ["$scope", "$state", "notify", "$window", "ComprobantesHistoricoLangFactory", "ComprobantesFactoryHistorico", "CsvFactory", "UtilFactory", "baseurl", "$filter", "CONFIGURATION", "filterFactory", "$modal", "NotificacionHistoricoMailFactory", "SucursalesFactory", function ($scope, $state, notify,
    $window, ComprobantesHistoricoLangFactory, ComprobantesFactoryHistorico, CsvFactory, UtilFactory, baseurl, $filter,
    CONFIGURATION, filterFactory, $modal, NotificacionHistoricoMailFactory, SucursalesFactory) {
    var defaultColumnOrder = ['id', 'numeroComprobante', 'fechaHora','tipo','tipoReceptor','tipoContribuyenteReceptor','tipoOperacion','tipoAnticipo',
      'tipoDocumentoReceptor','codigoPais','nombreFantasiaReceptor','numeroCasaReceptor','infoInteresEmisor','infoInteresFiscal',
      'codigoDepartamentoReceptor','departamentoReceptor','codigoDistritoRecptor','distrito','codigoCiudadReceptor',
      'descripcionCiudadReceptor','celularReceptor','codigoCliente','tipoImpuestoAfectado','timbrado','tipoDocElectronico',
      'fechaIniTimb','tipoTransaccion','moneda','tipoCambio','rucReceptor','nombreRazonSocial',
      'condicionVenta','entregaInicial','cuotas','gravada10','iva10','gravada5','iva5','exento','totalDescuento','total',
      'motivoEmision','nombreCsvProcesado','numeroLote','CDC','QR','detalle','pagos','documentos','supermercado',
      'detalleCuotas','sucursal','estado','correoElectronico','telefono','direccion','respuestaProcesoLote','plazos', 'CONFIGURATION'];

      var mostrarNotificacion = localStorage.getItem("MOSTRAR_NOTIFICACION_MAIL");
      var descargarPdfRango = localStorage.getItem("DESCARGAR_PDF_RANGO");
      var imprimirSinAprobacion = localStorage.getItem("IMPRIMIR_SIN_APROBACION");

      $scope.filtros = {
      };

      $scope.estados = [
        {
          id: 1,
          descripcion: "Aprobado"
        },
        {
          id: 2,
          descripcion: "Rechazado"
        },
        {
          id: 3,
          descripcion: "Pendiente"
        },
        {
          id: 4,
          descripcion: "Enviado"
        },
        {
          id: 5, 
          descripcion: "Cancelado"
        },
        {
          id: 6,
          descripcion: "Inutilizado"
        }
      ]

      $scope.tiposDocumentos = [{
        id: 1,
        valor: "Factura electrónica"
      },{
        id: 4,
        valor: "Autofactura"
      },{
        id: 5,
        valor: "Nota de crédito electrónica"
      },{
        id: 6, 
        valor: "Nota de débito electrónica"
      },{
        id: 7,
        valor: "Remisión electrónica"
      }];



      var monedaRender = function(data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda === 'USD') {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda === 'pesos') {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda === 'real') {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda === 'euro') {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
        } else {
            return '';
        }
    };

    var tcRender = function(data, type, row) {
      if (data) {
          return (
              data
              .toString()
              .replace(".", ",")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      );
      } else {
          return '';
      }
    };

    var tComprobnateRender = function(data, type, row) {
      if (data === '1') {
          return 'Factura electrónica'
      } else if (data === '2') {
        return 'Factura electrónica de exportación'
      } else if (data === '3') {
        return 'Factura electrónica de importación'
      } else if (data === '4') {
        return 'Autofactura electrónica'
      } else if (data === '5') {
        return 'Nota de credito electronica'
      } else if (data === '6') {
        return 'Nota de debito electronica'
      } else if (data === '7') {
        return 'Nota de remision electronica'
      } else if (data === '8') {
        return 'Comprobante de retencion'
      } else {
          return '';
      }
    };

    ComprobantesHistoricoLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      'resource': 'comprobanteshistorico',
      'title': 'Documentos Electrónicos Histórico',
      'factory': ComprobantesFactoryHistorico,
      'view': 'ComprobanteList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        {'data': 'id', 'title': $filter('translate')('ID'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'numeroComprobante', 'title': $filter('translate')('NUMERO_COMPROBANTE'), 'class': 'dt-center', visible: true, searchable: false},
        {'data': 'fechaHora', 'title': $filter('translate')('FECHA_HORA'), 'class': 'dt-center', visible: true, searchable: false},
        {'data': 'tipo', 'title': $filter('translate')('TIPO'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoReceptor', 'title': $filter('translate')('TIPO_RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoContribuyenteReceptor', 'title': $filter('translate')('TIPO_CONTRIBUYENTE RECEPTOR'),'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoOperacion', 'title': $filter('translate')('TIPO_OPERACION'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoAnticipo', 'title': $filter('translate')('TIPO_ANTICIPO'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoDocumentoReceptor', 'title': $filter('translate')('TIPO_DOCUMENTO RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'codigoPais', 'title': $filter('translate')('CODIGO_PAIS'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'nombreFantasiaReceptor', 'title': $filter('translate')('NOMBRE_FANTASIA RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'nombreRazonSocial', 'title': $filter('translate')('NOMBRE_RAZON SOCIAL'), 'class': 'dt-center', visible: true, searchable: false},
        {'data': 'numeroCasaReceptor', 'title': $filter('translate')('NUMERO_CASA RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'infoInteresEmisor', 'title': $filter('translate')('INFO_INTERES EMISOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'infoInteresFiscal', 'title': $filter('translate')('INFO_INTERES FISCAL'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'codigoDepartamentoReceptor', 'title': $filter('translate')('CODIGO_DEPARTAMENTO RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'departamentoReceptor', 'title': $filter('translate')('DEPARTAMENTO_RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'codigoDistritoRecptor', 'title': $filter('translate')('CODIGO_DISTRITO RECPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'distrito', 'title': $filter('translate')('DISTRITO'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'codigoCiudadReceptor', 'title': $filter('translate')('CODIGO_CIUDAD RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'descripcionCiudadReceptor', 'title': $filter('translate')('DESCRIPCION_CIUDAD RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'celularReceptor', 'title': $filter('translate')('CELULAR_RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'codigoCliente', 'title': $filter('translate')('CODIGO_CLIENTE'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoImpuestoAfectado', 'title': $filter('translate')('TIPO_IMPUESTO AFECTADO'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'timbrado', 'title': $filter('translate')('TIMBRADO'), 'class': 'dt-center', visible: true, searchable: false},
        {'data': 'estado', 'title': $filter('translate')('ESTADOS'), 'class': 'dt-center', visible: true, searchable: false},
        {'data': 'tipoDocElectronico', 'title': $filter('translate')('TIPO_DOC ELECTRONICO'), 'class': 'dt-center', visible: true, 'render': tComprobnateRender, searchable: false},
        {'data': 'fechaIniTimb', 'title': $filter('translate')('FECHA_INI TIMB'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoTransaccion', 'title': $filter('translate')('TIPO_TRANSACCION'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'moneda', 'title': $filter('translate')('MONEDA'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'tipoCambio', 'title': $filter('translate')('TIPO_CAMBIO'), 'class': 'dt-center', visible: false, 'render': tcRender, searchable: false},
        {'data': 'rucReceptor', 'title': $filter('translate')('RUC_RECEPTOR'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'condicionVenta', 'title': $filter('translate')('CONDICION_VENTA'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'entregaInicial', 'title': $filter('translate')('ENTREGA_INICIAL'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'cuotas', 'title': $filter('translate')('CUOTAS'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'gravada10', 'title': $filter('translate')('GRAVADA10'), 'class': 'dt-center', visible: false, 'render': monedaRender, searchable: false},
        {'data': 'iva10', 'title': $filter('translate')('IVA10'), 'class': 'dt-center', visible: false, 'render': monedaRender, searchable: false},
        {'data': 'gravada5', 'title': $filter('translate')('GRAVADA5'), 'class': 'dt-center', visible: false, 'render': monedaRender, searchable: false},
        {'data': 'iva5', 'title': $filter('translate')('IVA5'), 'class': 'dt-center', visible: false, 'render': monedaRender, searchable: false},
        {'data': 'exento', 'title': $filter('translate')('EXENTO'), 'class': 'dt-center', visible: false, 'render': monedaRender, searchable: false},
        {'data': 'totalDescuento', 'title': $filter('translate')('TOTAL_DESCUENTO'), 'class': 'dt-center', visible: true, 'render': monedaRender, searchable: false},
        {'data': 'total', 'title': $filter('translate')('TOTAL'), 'class': 'dt-center', visible: true, 'render': monedaRender, searchable: false},
        {'data': 'motivoEmision', 'title': $filter('translate')('MOTIVO_EMISION'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'nombreCsvProcesado', 'title': $filter('translate')('NOMBRE_CSV PROCESADO'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'numeroLote', 'title': $filter('translate')('NUMERO_LOTE'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'CDC', 'title': $filter('translate')('C_D C'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'QR', 'title': $filter('translate')('Q_R'), 'class': 'dt-center', visible: false, searchable: false},
       // {'data': 'detalle', 'title': $filter('translate')('DETALLE')},
       // {'data': 'pagos', 'title': $filter('translate')('PAGOS')},
       // {'data': 'documentos', 'title': $filter('translate')('DOCUMENTOS')},
       // {'data': 'supermercado', 'title': $filter('translate')('SUPERMERCADO')},
       // {'data': 'detalleCuotas', 'title': $filter('translate')('DETALLE_CUOTAS')},
       // {'data': 'sucursal', 'title': $filter('translate')('SUCURSAL')},
        {'data': 'correoElectronico', 'title': $filter('translate')('CORREO_ELECTRONICO'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'telefono', 'title': $filter('translate')('TELEFONO'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'direccion', 'title': $filter('translate')('DIRECCION'), 'class': 'dt-center', visible: false, searchable: false},
        {'data': 'respuestaProcesoLote', 'title': $filter('translate')('RESPUESTA_LOTE'), 'class': 'dt-center', visible: true, searchable: false},
        {'data': 'plazos', 'title': $filter('translate')('PLAZOS'), 'class': 'dt-center', visible: false, searchable: false},
      ],
      //'detailRows': []
      'hasOptions' : true,
      'defaultOrderColumn' : 2,
      'defaultOrderDir' : "desc",
      'failedDeleteError': 'No se pudo borrar el documento. Verifique si no hay Accesos al Sistema asociados.',
      'hideAddMenu' : false,
      'hideEditMenu' : false,
      'hideViewMenu' : true,
      'hideRemoveMenu':true,
      'extraMenuOptions':
        [
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                CsvFactory.csv("comprobanteshistorico", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function(response) {
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          },
          {
            'title': 'Descargar KuDEs por Fecha',
            'icon': "glyphicon glyphicon-save",
            'showCondition': function() { 
              if (descargarPdfRango === "Sí") {
                return true;
              } else {
                  return false;
              }
             },
            'action': function () {
              var staticFilter = {};

              $scope.tituloModal = "Descargar KuDEs por Fecha";

              SucursalesFactory.getTimbrado(localStorage.getItem("EMPRESA")).then(function(response){

                $scope.timbrado = response.data;

                var modalInstance = $modal.open({
                  templateUrl: "views/directives/descargar-kudes-fecha-modal.html",
                  scope: $scope
                });

                $scope.cancel = function () {
                  modalInstance.dismiss("cancel");
                };

                $scope.fechasValidas = function(fechaInicio, fechaFin) {
                  if (fechaInicio == undefined || fechaInicio == null || fechaFin == undefined || fechaFin == null) {
                      return false; 
                  }
                  
                  if (fechaFin < fechaInicio) {
                      return false;
                  }
          
                  var diferenciaEnMilisegundos = fechaFin.getTime() - fechaInicio.getTime();
          
                  var diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
          
                  if (diferenciaEnDias > 4) {
                      return false; // Retorna falso si la diferencia es mayor a 5 días
                  }
          
                  return true;
              };

                $scope.ok = function (fechaInicio, fechaFin) {
                  console.log("Descargar KuDEs por Fecha");

                  $('#preparandoDescargaModal').modal('show');

                  $window.open(baseurl.getBaseUrl() + '/comprobantes/descargakudes?timbrado=' + $scope.timbrado + '&fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin);
  
                  $('#preparandoDescargaModal').modal('hide');
                  $state.go($state.current, {}, {reload: true});
                  modalInstance.close();
                  
                 }
              });

             }
          }
        ],
      extraRowOptions: [
        {
          templateToRender: "<button class='btn btn-primary' style='margin-right: 5px;' ng-click='goSET(<%=dataCustom%>)' title='" + $filter('translate')('GO_SET') + "' ng-class='{ hidden : !canSET(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-send'></span> </button>",
          customAttribute: ["estado", "QR"],
          conditionName: "canSET",
          conditionDef: function(atributos) {
            var estado = atributos[0];
            if (estado === "Aprobado" || estado === "Aprobado con observación" || estado === "Cancelado" ) {
                return true;
            } else {
                return false;
            }
          },
          functionName: "goSET",
          functionDef: function(atributos) {
            var QR = atributos[1];
            window.open(QR);
          }
        },{
          templateToRender: "<button title='" + $filter("translate")("DESCARGAR KuDE") +
                "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='descargarKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescarga(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
          functionName: "descargarKuDE",
          conditionName: "condicionDescarga",
          customAttribute: ["estado", "CDC"],
          conditionDef: function (atributos) {
            var estado = atributos[0];
            if (imprimirSinAprobacion === "Sí"){
              if (estado === "Aprobado" || estado ===  "Aprobado con observación" || estado ===  "Pendiente_homologacion" || estado === "Enviado" || estado === "Pendiente" || estado == "Error SIFEN" || estado === "Cancelado") {
                  return true;
              } else {
                  return false;
              }
            }else{
              if (estado === "Aprobado" || estado ===  "Aprobado con observación" || estado ===  "Pendiente_homologacion" || estado === "Cancelado") {
                return true;
              } else {
                  return false;
              }
            }
          },
          functionDef: function (atributos) {
            var cdc = atributos[1];
            $window.open(CONFIGURATION.serverFE+cdc,"_blank");
            $state.go($state.current, {}, {reload: true});
          }
        },
        {
          templateToRender: "<button title='" + $filter("translate")("REENVIAR_MAIL") +
                  "' class='btn btn-default' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{ hidden : !condicionReenviar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-envelope'></span> </button>",
          functionName: "reenviar",
          conditionName: "condicionReenviar",
          customAttribute: ["estado", "CDC"],
          conditionDef: function (atributos) {
              var estado = atributos[0];
              if (estado === "Aprobado" ||  estado ===  "Aprobado con observación") {
                  return true;
              } else {
                  return false;
              }
          },
          functionDef: function (atributos) {
            $scope.tituloModal = $filter("translate")(
              "REENVIAR_MAIL"
              );
              $scope.mensajeModal = $filter("translate")(
                "CONFIRMAR_REENVIAR_MAIL"
                );
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });


            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };

            $scope.ok = function () {

              var cdc = atributos[1];
              ComprobantesFactoryHistorico.reenviarMail(cdc).then(function(response) {
              if (response.status === 200) {
                notify({
                  message: "Envío de mail con éxito",
                  classes: "alert-success",
                  position: "right"
                });
              } else {
                notify({
                  message: "Error al enviar mail",
                  classes: "alert-danger",
                  position: "right"
                });
                $state.go($state.current, {}, {reload: true});
              }},
              function(error) {
                console.log("error " + error);
                notify({
                  message: "Error al enviar mail",
                  classes: "alert-danger",
                  position: "right"
                });
              });
            }
          }
      },
      {
        templateToRender: "<button class='btn btn-primary' style='margin-right: 5px;' ng-click='goVerNotificacion(<%=dataCustom%>)' title='" + $filter('translate')('GO_VERNOTIFICACION') + "' ng-class='{ hidden : !canVerNotificacion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-comment'></span> </button>",
        customAttribute: ["id","estado"],
        conditionName: "canVerNotificacion",
        view: "datatables",
        conditionDef: function(atributos) {
          var estado = atributos[1];
          if (estado === "Aprobado" && mostrarNotificacion === "Sí") {
              return true;
          } else {
              return false;
          }
        },
        functionName: "goVerNotificacion",
        functionDef: function(atributos) {
          var id = atributos[0];
          var defaultColumnOrder = [
              "id",
              "evento",
              "fecha",
              "destinatario"
          ];
  
          $scope.optionsDTModal = {
              resource: "snsnotificacionhistorico",
              title: $filter("translate")("NOTIFICACION_MAIL"),
              factory: NotificacionHistoricoMailFactory,
              view: "BaseList",
              defaultColumnOrder: defaultColumnOrder,
              columns: [{
                      data: "id",
                      title: $filter("translate")("ID"),
                      type: "number-range",
                      visible: false
                  },
                  {
                      data: "evento",
                      title: $filter("translate")("EVENTO")
                  },
                  {
                    data: "fecha",
                    title: $filter("translate")("FECHA")
                  },
                  {
                    data: "destinatario",
                    title: $filter("translate")("DESTINATARIO")
                },
              ],
              hasOptions: false,
              hideAddMenu: true,
              hideEditMenu: true,
              hideViewMenu: true,
              hideRemoveMenu: true,
              defaultOrderColumn: 0,
              defaultOrderDir: "desc",
              staticFilter: {
                  search: filterFactory
                      .and([{
                          path: "facturaCabecera.id",
                          equals: id
                      }])
                      .value()
              }
          };
  
          var modalInstance = $modal.open({
              template: "<div tdn-datatable=\"\" options=\"optionsDTModal\"></div>",
              scope: $scope,
              size: "lg"
          });
  
          $scope.cancel = function () {
            modalInstance.dismiss("cancel");
          };
        }
      }],
  };

  var empresasFilter = {};
  empresasFilter.search = filterFactory.and([{
      path: "empresa.id",
      equals: localStorage.getItem('EMPRESA')
  }]).value();

  $scope.options.staticFilter = empresasFilter;

  $scope.consultar = function(){
    var listaFiltros = [];
    listaFiltros.push({
        path: "empresa.id",
        equals: localStorage.getItem('EMPRESA')
    });
    if ($scope.filtros.numeroComprobante != null){
      listaFiltros.push({
          path: "numeroComprobante",
          like: "%" + $scope.filtros.numeroComprobante + "%"
      })
    }
    if ($scope.filtros.estado != null){
      listaFiltros.push({
          path: "estado",
          equals: $scope.filtros.estado.descripcion
      })
    }
    if ($scope.filtros.tipoComprobante != null){
      listaFiltros.push({
          path: "tipoDocElectronico",
          equals: $scope.filtros.tipoComprobante.id + ""
      })
    }
    if ($scope.filtros.fechaDesde != null){
      var parse1 = moment($scope.filtros.fechaDesde).format('DD/MM/YYYY');
      listaFiltros.push({
          path: "fechaHora",
          equalOrAfter: parse1
      })
    }
    if ($scope.filtros.fechaHasta != null){
      $scope.filtros.fechaHasta.setDate($scope.filtros.fechaHasta.getDate() + 1);
      var parse2 = moment($scope.filtros.fechaHasta).format('DD/MM/YYYY');
      listaFiltros.push({
          path: "fechaHora",
          before: parse2
      })
      $scope.filtros.fechaHasta.setDate($scope.filtros.fechaHasta.getDate() - 1);
    }
    if ($scope.filtros.respuestaProcesoLote != null){
      listaFiltros.push({
          path: "respuestaProcesoLote",
          like: "%"+$scope.filtros.respuestaProcesoLote+"%"
      })
    }
    if ($scope.filtros.razonSocial != null){
      listaFiltros.push({
          path: "nombreRazonSocial",
          like: "%" +$scope.filtros.razonSocial + "%"
      })
    }
    if ($scope.filtros.cdc != null){
      listaFiltros.push({
          path: "CDC",
          equals: $scope.filtros.cdc
      })
    }
    var staticFilter = {
      search: filterFactory.and(listaFiltros).value()
    }
    $scope.options.staticFilter = staticFilter;
  }
  $scope.cleanFilters = function(){
    $scope.filtros = {};
  }



});
}]);

angular.module("qualita.analitica", []).config(
    [
        "$stateProvider",
        function ($stateProvider) {
            $stateProvider
            .state("app.tableroresumenenviocomprobantes", {
                url: "/tableroresumenenviocomprobantes",
                templateUrl: "../../views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroresumenenviocomprobantes.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroresumenenviocomprobantes/form.html",
                controller: "TableroResumenEnvioComprobantesFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Resumen de envio de comprobantes",
                    description: "Ver Tablero de Resumen de envio de comprobantes"
                }
            })
        }
    ]);
'use strict';

angular.module('qualita.analitica')
    .controller('TableroResumenEnvioComprobantesFormCtrl', TableroResumenEnvioComprobantesFormCtrl);

    TableroResumenEnvioComprobantesFormCtrl.$inject = ["ParametrosFactory", "$sce", "EmpresaFactory",  "notify", "$state"];

function TableroResumenEnvioComprobantesFormCtrl(ParametrosFactory, $sce, EmpresaFactory, notify,$state) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Resumen de envio de comprobantes";
        vm.width = screen.width;
        vm.height = screen.height;
        //encontrar el ruc de la empresa que esta en login
        EmpresaFactory.get(localStorage.getItem("EMPRESA")).$promise.then(function (res){
            ParametrosFactory.getByCodigo('fuente_analitica_'+res.numeroDocumento).then(function (response) { 
                if(response != null || response != undefined){
                    vm.parametro = $sce.trustAsResourceUrl(response.valor);
                }else{
                    $state.go("app.main");
                    notify({
                        message: "Esta funcion no esta habilitada para su usuario.",
                        classes: "alert-warning",
                        position: "right"
                    });
                }
            });
        });
    }
}
/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.eventos', ['ngResource', 'checklist-model'])
    .config(
        ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
            function($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {

            $stateProvider
                .state('app.eventos', {
                    url: '/eventos',
                    templateUrl: 'views/template_base/index.html',
                    abstract: true,
                    ncyBreadcrumb: {
                        label: 'Registro de Eventos'
                    }
                })
                .state('app.eventos.list', {
                    url: '',
                    templateUrl: '../../views/template_base/list.html',
                    controller: 'EventosCtrl',
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: 'app.eventos',
                        label: 'Eventos',
                        description: 'Listado de Eventos',
                        skip: true
                    }
                })
            }
        ]
    )
    .animation('.repeated-item', function() {
        return {
            enter: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            leave: function(element, done) {
                element.css('opacity', 1);
                jQuery(element).animate({
                    opacity: 0
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            move: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                // optional onDone or onCancel callback
                // function to handle any post-animation
                // cleanup operations
                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            }

        }
    });

function eventoPrepService(EventosFactory, $stateParams) {
    return EventosFactory.get($stateParams.id, "EventosForm").$promise;
}
'use strict';
  angular.module('qualita.eventos')
  .controller('EventosCtrl', ["$scope", "$state", "notify", "$window", "ComprobantesLangFactory", "EventosFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", "CONFIGURATION", "filterFactory", "$modal", "NotificacionMailFactory", "EmpresaFactory", "ParametrosFactory", "SucursalesFactory", "AuthorizationService", function ($scope, $state, notify,
    $window, ComprobantesLangFactory, EventosFactory, CsvFactory, UtilFactory, baseurl, $filter,
    CONFIGURATION, filterFactory, $modal, NotificacionMailFactory, EmpresaFactory, ParametrosFactory, SucursalesFactory, AuthorizationService) {
    var defaultColumnOrder = ['id', 'numeroComprobante', 'fecha', 'nombreEvento', 'CONFIGURATION'];

      var vm = this

      $scope.filtros = {
      };

    var stringrender = function(data) {
      if (data) { 
        return data;
      } else {
          return '';
      }
    };

    function commafy( num ) {
      var str = num.toString().split(',');
      if (str[0].length >= 5) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');
      }
      if (str[1] && str[1].length >= 5) {
          str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      return str.join(',');
  }

    var tcRender = function(data, type, row) {
      if (data) {
          return (
              data
              .toString()
              .replace(".", ",")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      );
      } else {
          return '';
      }
    };

    var tComprobnateRender = function(data, type, row) {
      if (data === '1') {
          return 'Factura electrónica'
      } else if (data === '2') {
        return 'Factura electrónica de exportación'
      } else if (data === '3') {
        return 'Factura electrónica de importación'
      } else if (data === '4') {
        return 'Autofactura electrónica'
      } else if (data === '5') {
        return 'Nota de credito electronica'
      } else if (data === '6') {
        return 'Nota de debito electronica'
      } else if (data === '7') {
        return 'Nota de remision electronica'
      } else if (data === '8') {
        return 'Comprobante de retencion'
      } else {
          return '';
      }
    };

    ComprobantesLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      'resource': 'eventos',
      'title': 'Registro de Eventos',
      'factory': EventosFactory,
      'view': 'EventoList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        {'data': 'id', 'title': $filter('translate')('ID'), 'class': 'dt-center', 'searchable': false},
        {'data': 'numeroComprobante', 'title': $filter('translate')('NUMERO_COMPROBANTE'), 'class': 'dt-center', visible: true,'searchable': false},
        {'data': 'fecha', 'title': $filter('translate')('FECHA'), 'class': 'dt-center', visible: true,'searchable': false},
        {'data': 'nombreEvento', 'title': $filter('translate')('NOMBRE_EVENTO'), 'class': 'dt-center','searchable': false},
      ],
      //'detailRows': []
      'hasOptions' : false,
      'defaultOrderColumn' : 2,
      'defaultOrderDir' : "desc",
      'failedDeleteError': 'No se pudo borrar el documento. Verifique si no hay Accesos al Sistema asociados.',
      'hideAddMenu' : false,
      'hideEditMenu' : true,
      'hideViewMenu' : true,
      'hideRemoveMenu':true,
  };

  var empresasFilter = {};
  empresasFilter.search = filterFactory.and([{
      path: "empresa.id",
      equals: localStorage.getItem('EMPRESA')
  }]).value();

  $scope.options.staticFilter = empresasFilter;

  $scope.validarNumeroComprobanteHasta= function(numeroDesde, numeroHasta){
    return numeroHasta > numeroDesde;
  }
});
}]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.eventos')
.factory('EventosFactory', EventosFactory);

EventosFactory.$inject = ['$resource', 'baseurl','$http'];

function EventosFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get
  };

  var Eventos = $resource( baseurl.getBaseUrl() + "/eventos/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  function all(params, view) {

    if (params) {
      params.view = view!=undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'BaseList';
    }
    return Eventos.query(params);
  }

  function get(id, view) {
    return Eventos.get({id: id, view: view!=undefined ? view : 'base'});
  }

  return service;
}
